import { HttpRequest } from "./http.request.model";
import { IqError } from "../error-normalizer.service";

export class HttpError extends Error {
  constructor(
    public readonly request: HttpRequest,
    public readonly previous: Error,
    public readonly status: number | null = null,
    public readonly iqError: IqError | null = null
  ) {
    super(previous.message);
  }
}
