<div id="session-expired-overlay" *ngIf="(isLoggedIn|async)===false">
    <app-card
            headerIcon="login"
            [headerTitle]="'components.session_expired.title'|trans"
            [loading]="refreshLoginInProgress">

        <p>{{'components.session_expired.description'|trans}}</p>
        <form class="form" *ngIf="lastUserEmail">
            <mat-form-field appearance="outline">
                <mat-icon matPrefix>email</mat-icon>
                <input type="text"
                       name="email"
                       matInput
                       autocomplete="username"
                       readonly
                       placeholder="Email..."
                       [value]="lastUserEmail">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-icon matPrefix>lock_outline</mat-icon>
                <input type="password"
                       name="password"
                       matInput
                       autocomplete="current-password"
                       placeholder="Password..."
                       [(ngModel)]="passwordValue"
                       (keydown.enter)="login()">
            </mat-form-field>
        </form>

        <button app-card-footer-action mat-raised-button color="primary" (click)="refreshLogin()"
                *ngIf="refreshTokenAvailable"
                [disabled]="refreshLoginInProgress"
                [matTooltip]="'components.session_expired.btn.refresh_login.hint'|trans">
            {{'components.session_expired.btn.refresh_login'|trans}}
        </button>
        <button app-card-footer-action mat-raised-button color="primary" (click)="login()"
                *ngIf="lastUserEmail"
                [disabled]="refreshLoginInProgress || !passwordValue"
                [matTooltip]="'components.session_expired.btn.login.hint'|trans">
            {{'components.session_expired.btn.login'|trans}}
        </button>
        <button app-card-footer-action mat-raised-button color="primary" (click)="logout()"
                [disabled]="refreshLoginInProgress"
                [matTooltip]="'components.session_expired.btn.logout.hint'|trans">
            {{'components.session_expired.btn.logout'|trans}}
        </button>
    </app-card>
</div>
