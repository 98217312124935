import { assertStringNotEmpty, isStringNotEmpty } from './string-not-empty';
import type { StringNotEmpty } from './string-not-empty';

export type StringNormalizedFieldAlias = StringNotEmpty & {
  __iq_type_string_normalized_field_alias: never;
};

export function isStringNormalizedFieldAlias(
  value: StringNormalizedFieldAlias | string | unknown
): value is StringNormalizedFieldAlias {
  return isStringNotEmpty(value);
}

export function assertStringNormalizedFieldAlias(
  value: StringNormalizedFieldAlias | string | unknown
): asserts value is StringNormalizedFieldAlias {
  assertStringNotEmpty(value);
}

export function validStringNormalizedFieldAlias(
  value: StringNormalizedFieldAlias | string | unknown
): StringNormalizedFieldAlias {
  assertStringNormalizedFieldAlias(value);
  return value;
}
