import { BehaviorSubject, firstValueFrom, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { validDefined } from "src/../../src/types/defined";

interface ItemWithId {
  id: string | number;
}

type ItemId = string | number;

export abstract class ItemListStoreService<T extends ItemWithId> {
  private _list = new BehaviorSubject<T[] | null>(null);
  public _observable: Observable<T[]>;

  constructor() {
    this._observable = this._list
      .asObservable()
      .pipe(map((list) => (list ? list.map((i) => i) : [])));
  }

  public get observable(): Observable<T[]> {
    return this._observable;
  }

  public get current(): T[] {
    return this.getArrValue();
  }

  public currentItemById(item: T | ItemId): T | null {
    const filtered = this.getArrValue().filter((i) => this.isSameItem(i, item));
    return filtered[0] ?? null;
  }

  public isLoaded(): boolean {
    return this._list.getValue() !== null;
  }

  public updateList(list: T[]) {
    this._list.next(list);
  }

  public initList(list: T[]) {
    if (this.isLoaded()) {
      console.debug(
        "ItemListStoreService: List already loaded, no update.",
        this.constructor.name
      );
      return;
    }

    console.debug(
      "ItemListStoreService: List not loaded, update.",
      this.constructor.name,
      list
    );
    this.updateList(list);
  }

  public clearList() {
    this._list.next(null);
  }

  private isSameItem(a: T | ItemId, b: T | ItemId): boolean {
    return (
      (typeof a === "number" || typeof a === "string" ? a : a.id) ===
      (typeof b === "number" || typeof b === "string" ? b : b.id)
    );
  }

  private getArrValue(): T[] {
    const list = this._list.getValue();
    return list === null ? [] : list.map((i) => i);
  }

  public hasItem(item: T | ItemId): boolean {
    const list = this.getArrValue();
    for (let i = 0; i < list.length; i++) {
      if (this.isSameItem(validDefined(list[i]), item)) {
        return true;
      }
    }

    return false;
  }

  public addItem(item: T) {
    if (this.hasItem(item)) {
      throw new Error(
        "Item already present in list, did you mean to update item?"
      );
    }

    const newList = this.getArrValue();
    newList.push(item);
    this._list.next(newList);
  }

  public updateItemById(item: T) {
    if (!this.hasItem(item)) {
      throw new Error("Item not present in list, did you mean to add item?");
    }

    const newList = this.getArrValue().map((i) => {
      if (this.isSameItem(i, item)) {
        return item;
      }
      return i;
    });
    this._list.next(newList);
  }

  public removeItemById(item: T | ItemId) {
    if (!this.hasItem(item)) {
      throw new Error("Item not present in list, is it already deleted?");
    }

    const newList = this.getArrValue().filter((i) => !this.isSameItem(i, item));
    this._list.next(newList);
  }

  public async awaitIsLoaded(): Promise<T[]> {
    return firstValueFrom(
      this.observable.pipe(filter((value): value is T[] => value !== null))
    );
  }
}
