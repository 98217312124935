import { Injectable } from "@angular/core";
import { ItemListStoreService } from "./item-list.store.service";
import { TenantWithDatasourcesCount } from "src/app/_services/api/tenant.service";

@Injectable({
  providedIn: "root",
})
export class TenantsStoreService extends ItemListStoreService<TenantWithDatasourcesCount> {
  public initList(list: TenantWithDatasourcesCount[]) {
    super.initList(list);
  }

  public updateList(list: TenantWithDatasourcesCount[]) {
    super.updateList(list);
  }

  public addItem(item: TenantWithDatasourcesCount) {
    super.addItem(item);
  }

  public updateItemById(item: TenantWithDatasourcesCount) {
    super.updateItemById(item);
  }

  public removeItemById(item: TenantWithDatasourcesCount | number | string) {
    super.removeItemById(item);
  }
}
