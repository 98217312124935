import { NgModule } from "@angular/core";
import { CanPipe } from "src/app/_pipes/can.pipe";
import { IsAdminDirective } from "src/app/_directives/is-admin.directive";
import { IsAdminDebugDirective } from "src/app/_directives/is-admin-debug.directive";
import { CannotPipe } from "src/app/_pipes/cannot.pipe";

@NgModule({
  exports: [CanPipe, CannotPipe, IsAdminDirective, IsAdminDebugDirective],
  imports: [],
  declarations: [CanPipe, CannotPipe, IsAdminDirective, IsAdminDebugDirective],
  providers: [],
})
export class AppAuthModule {}
