import { Component, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { AuthService } from "src/app/_services/auth.service";
import {
  Translation,
  TranslationsService,
} from "src/app/_services/translations.service";
import { ToastService } from "src/app/_services/toast.service";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-login-cmp",
  templateUrl: "./login.component.html",
  styleUrls: ["../assets/scss/front-sites.component.scss"],
})
export class LoginComponent implements OnInit {
  public form: FormGroup<{
    username: FormControl<string | null>;
    password: FormControl<string | null>;
  }>;
  public loginInvalid: boolean;
  public loading: boolean;
  public success: boolean;

  constructor(
    private _authService: AuthService,
    private _translationsService: TranslationsService,
    private _toastService: ToastService
  ) {}

  ngOnInit() {
    this.form = new FormGroup<{
      username: FormControl<string | null>;
      password: FormControl<string | null>;
    }>({
      username: new FormControl<string>("", [
        Validators.email,
        Validators.required,
      ]),
      password: new FormControl<string>("", Validators.required),
    });
  }

  async onSubmit() {
    this.loginInvalid = false;
    if (this.form.valid) {
      const username = this.form.controls["username"].value;
      const password = this.form.controls["password"].value;
      if (!username || !password) {
        return;
      }

      this.loading = true;
      this._authService
        .login({ username, password })
        .then((success: boolean) => {
          if (success) {
            this.success = true;
            this._toastService.success(
              new Translation("pages.login.toastr.success")
            );
          } else {
            if (!window.navigator.onLine) {
              this._toastService.warning(
                new Translation("pages.login.toastr.conn_failed.message"),
                new Translation("pages.login.toastr.conn_failed.title")
              );
            } else {
              this._toastService.warning(
                new Translation(
                  "pages.login.toastr.check_pwd_username.message"
                ),
                new Translation("pages.login.toastr.check_pwd_username.title")
              );
            }
            this.loginInvalid = true;
            this.loading = false;
          }
        });
    } else {
      const hints: string[] = [];
      if (!this.form.controls["username"].valid) {
        await this._translationsService
          .trans("pages.login.hints.username")
          .then((translation: string) => {
            hints.push(translation);
          });
      }
      if (!this.form.controls["password"].valid) {
        await this._translationsService
          .trans("pages.login.hints.password")
          .then((translation: string) => {
            hints.push(translation);
          });
      }
      if (hints.length > 0) {
        this._toastService.warning(
          new Translation("pages.login.hints.toastr.message"),
          new Translation("pages.login.hints.toastr.title")
        );
      }

      this.loading = false;
      this.success = false;
    }
  }
}
