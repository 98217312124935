import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";
import { PersistentValueStoreService } from "src/app/_store/generic/persistent-value.store.service";

@Injectable({
  providedIn: "root",
})
export class DarkModeRepositoryService {
  private _store: PersistentValueStoreService<boolean>;
  private _observable: Observable<boolean>;

  constructor() {
    this._store =
      PersistentValueStoreService.createBooleanFromLocalStorage(
        "iq.repo.darkMode"
      );
    this._observable = this._store.observable
      .pipe(map((value) => value ?? false))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));
  }

  public get current(): boolean {
    return this._store.current ?? false;
  }

  public get observable(): Observable<boolean> {
    return this._observable;
  }

  public toggle(): void {
    return this._store.updateValue(!this.current);
  }
}
