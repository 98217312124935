import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnChanges {
  private static readonly ROOT_DIALOG_CLASS = "cdk-overlay-pane";
  private static readonly WITH_ICON_CLASS = "app-card-with-icon";
  @Input() public headerIcon: string | null = null;
  @Input() public headerTitle: string | null = null;
  @Input() public loading: boolean = false;
  @Input() public actionsPosition: "left" | "right" | "spread" | "center" =
    "spread";
  @Input() public actionsInverseOrder: boolean = false;
  @Input() public hasCloseButton: boolean = false;
  @Output() public cardClose = new EventEmitter<void>();

  constructor(private _elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const hasIcon = !!(changes.headerIcon ?? this.headerIcon);
    let element = this._elementRef.nativeElement;

    if (!element) {
      return;
    }

    if (hasIcon) {
      element.classList.add(CardComponent.WITH_ICON_CLASS);
    } else {
      element.classList.remove(CardComponent.WITH_ICON_CLASS);
    }

    while (
      element &&
      !element.classList.contains(CardComponent.ROOT_DIALOG_CLASS)
    ) {
      element = element.parentElement;
    }

    if (!element) {
      return;
    }

    if (hasIcon) {
      element.classList.add(CardComponent.WITH_ICON_CLASS);
    } else {
      element.classList.remove(CardComponent.WITH_ICON_CLASS);
    }
  }

  public tryClose(): void {
    if (this.loading) {
      return;
    }

    this.cardClose.emit();
  }
}
