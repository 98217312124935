import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppTransModule } from "src/app/_modules/app-trans.module";
import { MatIconModule } from "@angular/material/icon";
import { CardComponent } from "./card.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ButtonListModule } from "../button-list/button-list.module";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    AppTransModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ButtonListModule,
    MatCardModule,
    MatButtonModule,
  ],
  declarations: [CardComponent],
  exports: [CardComponent],
})
export class CardModule {}
