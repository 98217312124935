import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from "rxjs/operators";
import { PersistentValueStoreService } from "src/app/_store/generic/persistent-value.store.service";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";

@Injectable({
  providedIn: "root",
})
export class DebugModeRepositoryService {
  private _store: PersistentValueStoreService<boolean>;
  private _observable: Observable<boolean>;

  constructor(private _userStore: AuthUserReadonlyRepositoryService) {
    this._store =
      PersistentValueStoreService.createBooleanFromLocalStorage(
        "iq.repo.debugMode"
      );
    this._observable = this._store.observable
      .pipe(map((value) => value ?? false))
      .pipe(distinctUntilChanged())
      .pipe(
        switchMap((darkMode) => {
          if (!darkMode) {
            return of<boolean>(false);
          }

          return this._userStore.observable.pipe(
            map((u) => !!(u && u.isAdmin()))
          );
        })
      )
      .pipe(shareReplay(1));
  }

  public get current(): boolean {
    return (
      (this._store.current ?? false) &&
      (this._userStore.current?.isAdmin() ?? false)
    );
  }

  public get observable(): Observable<boolean> {
    return this._observable;
  }

  public toggle(): void {
    if (!this._userStore.current?.isAdmin()) {
      return;
    }

    return this._store.updateValue(!this.current);
  }
}
