import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MatNativeDateModule } from "@angular/material/core";
import { AppComponent } from "./app.component";
import { SidebarModule } from "./sidebar/sidebar.module";
import { FooterModule } from "src/app/shared/footer/footer.module";
import { NavbarModule } from "src/app/shared/navbar/navbar.module";
import { LoginComponent } from "./pages/login/login.component";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes } from "./app.routing";
import { HttpClientModule } from "@angular/common/http";
import { AuthService } from "src/app/_services/auth.service";
import { BrowserModule } from "@angular/platform-browser";
import { LoadingOverlayComponent } from "src/app/_models/loading-overlay/loading-overlay.component";
import { PasswordResetComponent } from "./pages/password-reset/password-reset.component";
import { PasswordResetPasswordComponent } from "./pages/password-reset-password/password-reset-password.component";
import { GenericDialogComponent } from "./dialogs/generic-dialog/generic-dialog.component";
import { GoogleDatastudioComponent } from "./connectors/google-datastudio/google-datastudio.component";
import { ConfirmationDialogComponent } from "./dialogs/confirmation-dialog/confirmation-dialog.component";
import { SharedModule } from "src/app/_modules/shared.module";
import { MaterialModule } from "src/app/_modules/material.module";
import { OauthComponent } from "./pages/oauth/oauth.component";
import { InputDialogComponent } from "./dialogs/input-dialog/input-dialog.component";
import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeDe from "@angular/common/locales/de";
import { ErrorHandlerService } from "src/app/_services/error-handler.service";
import { TableColumnsDialogComponent } from "./dialogs/table-columns-dialog/table-columns-dialog.component";
import { CDK_DRAG_CONFIG, DragDropModule } from "@angular/cdk/drag-drop";
import { SessionExpiredModule } from "src/app/shared/session-expired/session-expired.module";
import { ToastrModule } from "ngx-toastr";
import { ToastComponent } from "src/app/shared/misc/toast/toast.component";
import { CardModule } from "src/app/shared/card/card.module";
import { ButtonListModule } from "src/app/shared/button-list/button-list.module";
import { AuditLogsDialogComponent } from "./dialogs/audit-logs-dialog/audit-logs-dialog.component";
import { DateDialogComponent } from "./dialogs/date-dialog/date-dialog.component";
import { SelectDialogComponent } from "./dialogs/select-dialog/select-dialog.component";

registerLocaleData(localeEn);
registerLocaleData(localeDe);

const MaterialCdkDragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: false,
    }),
    MaterialModule,
    MatNativeDateModule,
    SidebarModule,
    NavbarModule,
    SessionExpiredModule,
    FooterModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      toastClass: "",
      positionClass: "toast-bottom-left",
      newestOnTop: false,
    }),
    SharedModule,
    DragDropModule,
    CardModule,
    ButtonListModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LoginComponent,
    LoadingOverlayComponent,
    PasswordResetComponent,
    PasswordResetPasswordComponent,
    GenericDialogComponent,
    GoogleDatastudioComponent,
    ConfirmationDialogComponent,
    InputDialogComponent,
    DateDialogComponent,
    SelectDialogComponent,
    OauthComponent,
    TableColumnsDialogComponent,
    ToastComponent,
    AuditLogsDialogComponent,
  ],
  bootstrap: [AppComponent],
  exports: [],
  providers: [
    AuthService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: CDK_DRAG_CONFIG, useValue: MaterialCdkDragConfig },
  ],
})
export class AppModule {}
