<div class="iq-logo">
    <img class="iq-logo-img" src="/assets/img/dc_iq.png" alt="">
    <img class="iq-logo-text"
         [src]="($darkMode|async)?'/assets/img/iq/iq_text_dark_mode.svg':'/assets/img/iq/iq_text.svg'"
         alt="Intelligence Qube">
</div>

<mat-divider></mat-divider>

<div class="sidebar-wrapper">
    <ng-container *ngFor="let menuItem of (menuItems|async)">
        <button mat-raised-button color="primary"
                (click)="toggleMainItem(menuItem)">
            <span>{{menuItem.title|translatable}}</span>
            <mat-icon>{{isMainItemOpened(menuItem) ? 'expand_more' : 'expand_less'}}</mat-icon>
        </button>
        <mat-nav-list *ngIf="menuItem.children.length>0"
                      [ngClass]="{'closed':!isMainItemOpened(menuItem)}">
            <ng-container *ngFor="let childItem of menuItem.children;">
                <mat-list-item
                        [routerLink]="[menuItem.path, childItem.path]"
                        routerLinkActive="active">
                    <mat-icon matListItemIcon>{{childItem.icon}}</mat-icon>
                    <span matLine>{{childItem.title|trans}}<app-admin-icon *ngIf="childItem.dev"></app-admin-icon></span>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>
    </ng-container>
</div>
