import { NgModule } from "@angular/core";
import { InputFocusNextPrevDirective } from "./input-focus-next-prev.directive";
import { InputDynamicWidthDirective } from "./input-dynamic-width.directive";

@NgModule({
  imports: [],
  exports: [InputDynamicWidthDirective, InputFocusNextPrevDirective],
  declarations: [InputDynamicWidthDirective, InputFocusNextPrevDirective],
})
export class InputDirectivesModule {}
