import { BehaviorSubject, Observable } from "rxjs";

export class CachedValueStoreService<T> {
  private _value: BehaviorSubject<T | null>;
  private _observable: Observable<T | null>;

  private constructor(initialValue: T | null) {
    this._value = new BehaviorSubject<T | null>(initialValue);
    this._observable = this._value.asObservable();
  }

  public static create<T>(initialValue: T | null = null) {
    return new CachedValueStoreService<T>(initialValue);
  }

  public get observable(): Observable<T | null> {
    return this._observable;
  }

  public get current(): T | null {
    return this._value.getValue();
  }

  public hasData(): boolean {
    return this._value.getValue() !== null;
  }

  public updateValue(value: T | null) {
    this._value.next(value);
  }

  public clearValue() {
    this.updateValue(null);
  }
}
