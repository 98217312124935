import { Observable } from "rxjs";
import { CachedValueStoreService } from "./cached-value.store.service";
import { isUuid, Uuid } from "src/../../src/types/uuid";

export class CachedItemListStoreService<T, K> {
  private constructor(
    private readonly _store: CachedValueStoreService<T[]>,
    private readonly _instanceOfItemId: (object: T | K) => object is K,
    private readonly _getItemMainId: (item: T) => K,
    private readonly _itemHasKey: (item: T, key: K) => boolean
  ) {}

  public static create<T, K>(
    store: CachedValueStoreService<T[]>,
    instanceOfItemId: (object: T | K) => object is K,
    getItemMainId: (item: T) => K,
    itemHasKey: (item: T, key: K) => boolean
  ): CachedItemListStoreService<T, K> {
    return new CachedItemListStoreService<T, K>(
      store,
      instanceOfItemId,
      getItemMainId,
      itemHasKey
    );
  }

  public static createForUuid<T extends { id: Uuid }>(
    store: CachedValueStoreService<T[]> | null = null
  ): CachedItemListStoreService<T, Uuid> {
    return CachedItemListStoreService.create<T, Uuid>(
      store ?? CachedValueStoreService.create<T[]>(),
      (object: Uuid | T): object is Uuid => isUuid(object),
      (item: T): Uuid => item.id,
      (item: T, key: Uuid): boolean => item.id === key
    );
  }

  private _hasItem(item: T | K): boolean {
    const key = this._instanceOfItemId(item) ? item : this._getItemMainId(item);

    return (this._store.current ?? []).some((i) => this._itemHasKey(i, key));
  }

  public addItem(item: T) {
    if (this._hasItem(item)) {
      throw new Error(
        "Item already present in list, did you mean to update item?"
      );
    }

    const newList = this._store.current ?? [];
    newList.push(item);
    this._store.updateValue(newList);
  }

  public updateItemById(newItem: T) {
    if (!this._hasItem(newItem)) {
      throw new Error("Item not present in list, did you mean to add item?");
    }

    const newItemKey = this._getItemMainId(newItem);
    const newList = (this._store.current ?? []).map((existingItem) => {
      if (this._itemHasKey(existingItem, newItemKey)) {
        return newItem;
      }
      return existingItem;
    });
    this._store.updateValue(newList);
  }

  public addOrUpdateItem(item: T) {
    if (this._hasItem(item)) {
      this.updateItemById(item);
    } else {
      this.addItem(item);
    }
  }

  public removeItemById(toDeleteItem: T | K) {
    if (!this._hasItem(toDeleteItem)) {
      console.warn("Item not present in list, is it already deleted?");
      return;
    }

    const toDeleteItemKey = this._instanceOfItemId(toDeleteItem)
      ? toDeleteItem
      : this._getItemMainId(toDeleteItem);
    const newList = (this._store.current ?? []).filter(
      (existingItem) => !this._itemHasKey(existingItem, toDeleteItemKey)
    );
    this._store.updateValue(newList);
  }

  public get observable(): Observable<T[] | null> {
    return this._store.observable;
  }

  public get current(): T[] | null {
    return this._store.current;
  }

  public isLoaded(): boolean {
    return this._store.hasData();
  }

  public updateValue(value: T[]) {
    return this._store.updateValue(value);
  }

  public clearValue() {
    return this._store.clearValue();
  }
}
