type CommonValidInteger = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 100 | 1000 | 1024;

export type Integer = number & ({ __iq_type_integer: never } | CommonValidInteger);

export function isInteger(value: Integer | number | unknown): value is Integer {
  return typeof value === 'number' && Number.isInteger(value);
}

export function assertInteger(value: Integer | string | unknown): asserts value is Integer {
  if (!isInteger(value)) {
    throw new Error("Expected integer, got '" + value + "'.");
  }
}

export function validInteger(value: Integer | unknown): Integer {
  assertInteger(value);
  return value;
}

export function castInteger(value: Integer | number): Integer {
  if (!Number.isInteger(value)) {
    value = parseInt(value.toString());
  }
  return validInteger(value);
}
