<div class="iq-auth-layout">
    <div class="iq-main-panel">
        <div class="container">
            <div class="row">
                <div class="col-xl-5 col-lg-5 col-md-8 col-sm-8 col-xs-12">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <img src="/assets/img/iq/digital_control_logo_white.svg" alt="Digital Control GmbH & Co.KG"
             class="dc-logo-login">
    </div>
</div>