import { Toast, ToastPackage, ToastrService } from "ngx-toastr";
import { Component } from "@angular/core";
import { CustomToastConfig } from "src/app/_services/toast.service";

@Component({
  selector: "app-toast-component",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent extends Toast {
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  get config(): CustomToastConfig {
    return this.toastPackage.config as CustomToastConfig;
  }

  get customIcon(): string | null {
    switch (this.config.custom.type) {
      case "info":
        return "question_mark";
      case "success":
        return "done";
      case "warning":
        return "warning";
      case "danger":
        return "bolt";
    }
  }

  action(event: Event, action: string) {
    event.stopPropagation();
    this.toastPackage.triggerAction(action);
    return false;
  }
}
