import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MaterialModule } from "src/app/_modules/material.module";
import { SessionExpiredComponent } from "./session-expired.component";
import { FormsModule } from "@angular/forms";
import { CardModule } from "../card/card.module";
@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MaterialModule,
    FormsModule,
    CardModule,
  ],
  declarations: [SessionExpiredComponent],
  exports: [SessionExpiredComponent],
})
export class SessionExpiredModule {}
