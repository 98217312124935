import {
  DatasourceType as _DatasourceType,
  DATASOURCE_TYPES as _DATASOURCE_TYPES,
  getDatasourceTypeLabel,
} from "src/../../src/types/datasource-type";
import { StringNotEmpty } from "../../../../src/types/string-not-empty";
import { createDatasourceTypeStatusFromDatasourceType } from "../../../../src/types/datasource-type-status";

export const DATASOURCE_TYPE_IQ = "iq" as "iq" & StringNotEmpty;
export const DATASOURCE_TYPE_EXCEL = "excel" as "excel" & StringNotEmpty;
export const DATASOURCE_TYPE_EXCEL_FTP = "excel_ftp" as "excel_ftp" &
  StringNotEmpty;
export const DATASOURCE_TYPE_EXCEL_MAIL = "excel_mail" as "excel_mail" &
  StringNotEmpty;
export const DATASOURCE_TYPE_FINANCE_DESK = "finance_desk" as "finance_desk" &
  StringNotEmpty;

export type DatasourceType = _DatasourceType;

export const DATASOURCE_TYPES: DatasourceType[] = _DATASOURCE_TYPES;

export const DatasourceTypeGetName = getDatasourceTypeLabel;

export const DatasourceTypeGetStatus =
  createDatasourceTypeStatusFromDatasourceType;
