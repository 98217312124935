import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CurrentTenantService } from "src/app/_services/current-tenant.service";
import { map, tap } from "rxjs/operators";
import { ToastService } from "src/app/_services/toast.service";
import { Translation } from "src/app/_services/translations.service";

@Injectable({
  providedIn: "root",
})
export class TenantGuardService {
  constructor(
    private _currentTenantService: CurrentTenantService,
    private _router: Router,
    private _toastService: ToastService
  ) {}

  canActivate() {
    return this._currentTenantService.observable.pipe(
      map(
        (
          tenant: {
            id: string;
            name: string;
            defaultCurrency: string | null;
          } | null
        ) => {
          return tenant !== null;
        }
      ),
      tap((hasTenant) => {
        console.debug(
          "TenantGuardService",
          hasTenant ? "access granted" : "access denied"
        );
        if (!hasTenant) {
          this._router.navigate(["/admin/tenants"]);
          this._toastService.warning(
            new Translation("guards.no_tenant.message"),
            new Translation("guards.no_tenant.title")
          );
        }
      })
    );
  }
}
