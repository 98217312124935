import { assertStringNotEmpty } from './string-not-empty';

export type IqErrorId = string & { __iq_type_iq_error_id: never };

export function isIqErrorId(value: IqErrorId | string | unknown): value is IqErrorId {
  return typeof value === 'string' && /^I[a-zA-Z0-9]+Q[a-zA-Z0-9]+$/i.test(value);
}

export function assertIqErrorId(value: IqErrorId | string | unknown): asserts value is IqErrorId {
  if (!isIqErrorId(value)) {
    assertStringNotEmpty(value);
    throw new Error("Expected iq-error-id string, got '" + value + "'.");
  }
}

export function validIqErrorId(value: IqErrorId | string | unknown): IqErrorId {
  assertIqErrorId(value);
  return value;
}
