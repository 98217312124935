<app-card
        headerIcon="workspaces"
        [headerTitle]="'components.google_datastudio.title'|trans"
        [loading]="loading">
    <ng-container *ngIf="this.oAuthClient">
        <p>{{'components.google_datastudio.description'|trans:{clientName: this.oAuthClient.name} }}</p>
        <ul>
            <li *ngFor="let scope of this.scopes">{{scope}}</li>
        </ul>

        <ng-container *ngIf="!this.requiresTenantIndependent">
            <div>
                <mat-checkbox
                        (click)="$event.preventDefault(); $event.stopPropagation(); this.selectedTenant=null;"
                        [checked]="this.selectedTenant===null"
                >{{'components.google_datastudio.all_tenants'|trans}}
                </mat-checkbox>
            </div>
            <div *ngFor="let tenant of tenants">
                <mat-checkbox
                        (click)="$event.preventDefault(); $event.stopPropagation(); this.selectedTenant=tenant;"
                        [checked]="this.selectedTenant===tenant"
                >{{tenant.name}}</mat-checkbox>
            </div>
        </ng-container>
    </ng-container>

    <button app-card-footer-action
            *ngIf="false"
            mat-raised-button type="submit"
            tabindex="-1"
            color="accent"
            (click)="cancel()">
        {{'components.google_datastudio.cancel'|trans}}
    </button>
    <button app-card-footer-action
            *ngIf="this.oAuthClient && this.selectedTenant===null"
            (click)="confirmAll()"
            mat-raised-button
            color="primary"
            type="submit"
            tabindex="-1">
        {{'components.google_datastudio.confirm.all'|trans}}
    </button>
    <button app-card-footer-action
            *ngIf="this.oAuthClient && this.selectedTenant"
            (click)="confirmForTenant(this.selectedTenant)"
            mat-raised-button
            color="primary"
            type="submit"
            tabindex="-1">
        {{'components.google_datastudio.confirm.tenant'|trans:{tenantName: this.selectedTenant.name} }}
    </button>
</app-card>
