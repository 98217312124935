import { Injectable } from "@angular/core";
import { CurrentTenantService } from "../current-tenant.service";
import { ServerService } from "../http/server.service";
import { HostsService } from "../hosts.service";
import { Observable } from "rxjs";
import { TenantResponse } from "src/app/_models/tenant-response";
import { map } from "rxjs/operators";
import { IqUser } from "src/app/_models/iq-user";
import { ThemePalette } from "@angular/material/core";
import { Translatable } from "../translations.service";
import { AuthUserReadonlyRepositoryService } from "../repository/auth-user-readonly-repository.service";
import { TenantId, validTenantId } from "src/../../src/types/tenant-id";
import { UnixTimestampSeconds } from "src/../../src/types/unix-timestamp-seconds";
import { IqErrorId } from "src/../../src/types/iq-error-id";

export type ImportJobLogFilter =
  | "all"
  | "running"
  | "done"
  | "done_success"
  | "done_failure";

export interface ImportJobLog {
  id: string;
  timestampStarted: number;
  timestampDone: null | number;
  done: boolean;
  success: null | boolean;
  dataImported: null | boolean;
  import: {
    tenantId: TenantId;
    type: string;
    datasourceTemplateId: string;
    start?: string; // "2020-08-16"
    end?: string; // "2020-08-16"
  };
  iqErrorId: null | string;
  iqErrorMessage: null | string;
  iqErrorDescription: null | string;
}

export interface ImportJobLogWithStatus extends ImportJobLog {
  status: {
    title: Translatable;
    icon: string;
    color: ThemePalette;
  };
}

export interface TransferImportJob {
  id: string;
  sourceTenantId: TenantId;
  targetTenantId: TenantId;
  sharedDatasourceId: string;
  timestampStarted: number;
  timestampDone: null | number;
  done: boolean;
  success: null | boolean;
  iqErrorId: null | string;
  iqErrorMessage: null | string;
  iqErrorDescription: null | string;
}

export interface TransferImportJobWithStatus extends TransferImportJob {
  status: {
    title: string;
    icon: string;
    color: ThemePalette;
  };
}

export type ErrorLogLevel = "error" | "info";
export type ErrorLogSource = "frontend" | "backend";
export type ErrorLogFilter = "all" | ErrorLogLevel | ErrorLogSource;

export function isErrorLogFilter(value: any): value is ErrorLogFilter {
  return (
    value === "all" ||
    value === "error" ||
    value === "info" ||
    value === "frontend" ||
    value === "backend"
  );
}

export interface ErrorLog {
  id: string;
  message: string;
  timestamp: number;
  payload: {
    context?: any;
    id: string;
    status: number;
    message: string;
    description: string | null;
    uri: string | null;
    type: string | null;
    payload: {} | null;
    debugInfo: {
      class: string;
      message: string;
      code: 0;
      file: string;
      line: 80;
      trace: string[];
      additional: {
        user?: {
          id?: string | null;
          email?: string | null;
          name?: string | null;
          scopes?: string[];
          clientId?: string | null;
          tenantId?: string | null;
          tenantName?: string | null;
        };
      } | null;
    } | null;
    _resource?: {
      type?: string;
      labels?: {
        container_name?: string;
        service_name?: string;
        cluster_name?: string;
        namespace_name?: string;
        pod_name?: string;
        project_id?: string;
        location?: string;
      };
    };
    _labels?: {
      "compute.googleapis.com/resource_name"?: string;
      "k8s-pod/app"?: string;
      "k8s-pod/environment"?: string;
      "k8s-pod/pod-template-hash"?: string;
    };
  };
  level: ErrorLogLevel;
  source: ErrorLogSource;
}

@Injectable({
  providedIn: "root",
})
export class ManagementService {
  private readonly host: string;

  constructor(
    private _server: ServerService,
    private _hostsService: HostsService,
    private _authUserStore: AuthUserReadonlyRepositoryService,
    private _currentTenantService: CurrentTenantService
  ) {
    this.host = this._hostsService.managementHost;
  }

  private _tenantResponse<T>(
    tenantId: TenantId
  ): (data: T) => TenantResponse<T> {
    return (data) => new TenantResponse(tenantId, data);
  }

  public getImportJobLogs(
    tenantId: TenantId | null = null,
    filter: ImportJobLogFilter = "all",
    since: UnixTimestampSeconds | null = null
  ): Observable<ImportJobLog[]> {
    filter = filter ?? "all";

    return this._server.requestWithTenantId<ImportJobLog[]>(
      "GET",
      this.host + "/import_job?filter=" + encodeURIComponent(filter),
      tenantId,
      since
        ? {
            since: since,
          }
        : undefined
    );
  }

  private _getTransferJobLogsByX(
    x: "source" | "target",
    tenantId?: string
  ): Observable<TenantResponse<TransferImportJob[]>> {
    const safeTenantId = validTenantId(
      tenantId ?? this._currentTenantService.currentTenantId
    );

    return this._server
      .requestWithTenantId<TransferImportJob[]>(
        "GET",
        this.host + "/transfer_import_job/by_" + x,
        safeTenantId
      )
      .pipe(map(this._tenantResponse(safeTenantId)));
  }

  public getTransferJobLogsBySource(
    tenantId?: string
  ): Observable<TenantResponse<TransferImportJob[]>> {
    return this._getTransferJobLogsByX("source", tenantId);
  }

  public getTransferJobLogsByTarget(
    tenantId?: string
  ): Observable<TenantResponse<TransferImportJob[]>> {
    return this._getTransferJobLogsByX("target", tenantId);
  }

  public getErrors(
    type: ErrorLogFilter,
    start: UnixTimestampSeconds,
    end: UnixTimestampSeconds
  ): Observable<ErrorLog[]> {
    return this._server.request<ErrorLog[]>(
      "GET",
      this.host +
        "/error_debug_info" +
        "?filter=" +
        encodeURIComponent(type) +
        "&start=" +
        encodeURIComponent(start) +
        "&end=" +
        encodeURIComponent(end)
    );
  }

  public getErrorsById(errorId: string): Observable<ErrorLog[]> {
    return this._server.request<ErrorLog[]>(
      "GET",
      this.host + "/error_debug_info/" + errorId
    );
  }

  public logJsError(
    error: Error,
    additional: {
      prevErrors: string[];
      prevRequests: string[];
    }
  ): Observable<IqErrorId> {
    let message = error.message;

    let data: string;
    try {
      data = JSON.stringify(error);
      if (data === "{}" && error.stack) {
        data = JSON.stringify({
          stack: error.stack,
        });
      }
    } catch (ignore) {
      data = "";
    }

    let tenantId: TenantId | null = null;
    try {
      tenantId = this._currentTenantService.currentTenantId;
    } catch (ignore) {}
    let user: IqUser | null = null;
    try {
      user = this._authUserStore.current;
    } catch (ignore) {}

    return this._server
      .request<{ id: IqErrorId }>("POST", this.host + "/log_js_error", {
        message: message,
        payload: data,
        meta: {
          location: window.location.href,
          tenantId: tenantId,
          user: {
            id: user?.userId,
            email: user?.userEmail,
          },
          ...additional,
        },
      })
      .pipe(map((r) => r.id));
  }
}
