import { Injectable } from "@angular/core";
import { Router, UrlTree, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class BackwardsCompatibilityGuardService {
  constructor(private _router: Router) {}

  public static getRedirectUrl(
    router: Router,
    route: ActivatedRouteSnapshot
  ): UrlTree | null {
    const fullUrl = "/" + route.url.join("/");

    // _password_reset => /auth/new-password
    if (fullUrl === "/_password_reset") {
      return router.createUrlTree(["auth", "new-password"], {
        queryParams: route.queryParams,
        fragment: route.fragment ?? undefined,
      });
    }

    return null;
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<true | UrlTree> {
    return of(
      BackwardsCompatibilityGuardService.getRedirectUrl(this._router, route) ??
        true
    );
  }
}
