import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppTransModule } from "src/app/_modules/app-trans.module";
import { MatIconModule } from "@angular/material/icon";
import { ButtonListComponent } from "./button-list.component";

@NgModule({
  imports: [CommonModule, AppTransModule, MatIconModule],
  declarations: [ButtonListComponent],
  exports: [ButtonListComponent],
})
export class ButtonListModule {}
