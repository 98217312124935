import { Injectable } from "@angular/core";
import {
  IqError as _IqError,
  isIqError as _isIqError,
} from "src/../../src/types/iq-error";

export type IqError = _IqError;

export const isIqError: (err: unknown) => err is IqError = _isIqError;

@Injectable({
  providedIn: "root",
})
export class ErrorNormalizerService {
  constructor() {}

  public async normalize(
    error: any,
    httpStatus?: number | undefined
  ): Promise<IqError | Error> {
    if (typeof error === "string") {
      console.warn("ErrorNormalizerService: deprecated string error");
      return new Error(error);
    }

    if (
      error.hasOwnProperty("status") &&
      Number.isInteger(error.status) &&
      error.status >= 200 &&
      error.status <= 599
    ) {
      httpStatus = error.status;
    }

    if (error instanceof Blob) {
      return await this.normalize(JSON.parse(await error.text()), httpStatus);
    }

    if (
      typeof error === "object" &&
      error &&
      error.hasOwnProperty("iqError") &&
      typeof error.iqError === "object" &&
      error.iqError
    ) {
      const iqError: IqError = Object.assign({}, error.iqError);
      if (httpStatus) {
        iqError.httpStatus = httpStatus;
      }
      return iqError;
    }

    if (error instanceof Error) {
      return error;
    }

    if (isIqError(error)) {
      return error;
    }

    console.warn("ErrorNormalizerService: deprecated obj error", error);
    if (typeof error === "object" && error) {
      if (
        error.hasOwnProperty("error") &&
        typeof error.error === "object" &&
        error.error
      ) {
        return await this.normalize(error.error, httpStatus);
      }

      try {
        return new Error(JSON.stringify(error));
      } catch (e) {
        console.warn("ErrorNormalizerService: unsupported obj error", error);
        return new Error(error.toString());
      }
    }

    console.warn(
      "ErrorNormalizerService: unsupported error type",
      typeof error
    );
    return new Error(typeof error);
  }
}
