export class AccessToken {
  constructor(
    public readonly accessToken: string,
    public readonly expiresAt: number
  ) {}

  public toString(): string {
    return this.accessToken;
  }

  public getExpiresInSeconds(): number {
    return this.expiresAt - parseInt((Date.now() / 1000).toString());
  }
}
