import {
  DATASOURCE_TYPE_STATUS_BETA,
  DATASOURCE_TYPE_STATUS_DISABLED,
  DATASOURCE_TYPE_STATUS_STABLE,
  DatasourceTypeStatus
} from './datasource-type-status';

// DO NOT MODIFY THIS COMMENT autogenerated start
export const DATASOURCE_TYPE_IQ = 'iq' as const;
export const DATASOURCE_TYPE_FACEBOOK = 'facebook' as const;
export const DATASOURCE_TYPE_INSTAGRAM = 'instagram' as const;
export const DATASOURCE_TYPE_ADFORM = 'adform' as const;
export const DATASOURCE_TYPE_OMNITURE = 'omniture' as const;
export const DATASOURCE_TYPE_SPRINKLR = 'sprinklr' as const;
export const DATASOURCE_TYPE_GOOGLE_ADS = 'google_ads' as const;
export const DATASOURCE_TYPE_GOOGLE_ADWORDS = 'google_adwords' as const;
export const DATASOURCE_TYPE_GOOGLE_ANALYTICS_UA = 'google_analytics_ua' as const;
export const DATASOURCE_TYPE_GOOGLE_ANALYTICS_GA4 = 'google_analytics_ga4' as const;
export const DATASOURCE_TYPE_EXCEL = 'excel' as const;
export const DATASOURCE_TYPE_EXCEL_FTP = 'excel_ftp' as const;
export const DATASOURCE_TYPE_EXCEL_MAIL = 'excel_mail' as const;
export const DATASOURCE_TYPE_TABMO = 'tabmo' as const;
export const DATASOURCE_TYPE_MEDIA_DESK = 'media_desk' as const;
export const DATASOURCE_TYPE_ZULU5 = 'zulu5' as const;
export const DATASOURCE_TYPE_AMAZON_DSP = 'amazon_dsp' as const;
export const DATASOURCE_TYPE_ZEMANTA = 'zemanta' as const;
export const DATASOURCE_TYPE_AUDIENCE_PROJECT = 'audience_project' as const;
export const DATASOURCE_TYPE_AUDIENCE_PROJECT_NEXT = 'audience_project_next' as const;
export const DATASOURCE_TYPE_LINKED_IN = 'linked_in' as const;
export const DATASOURCE_TYPE_BING = 'bing' as const;
export const DATASOURCE_TYPE_TIKTOK_ADS = 'tiktok_ads' as const;
export const DATASOURCE_TYPE_TIKTOK_ACCOUNT = 'tiktok_account' as const;
export const DATASOURCE_TYPE_INDEED = 'indeed' as const;
export const DATASOURCE_TYPE_FINANCE_DESK = 'finance_desk' as const;
export const DATASOURCE_TYPE_PINTEREST = 'pinterest' as const;
export const DATASOURCE_TYPE_THE_TRADE_DESK = 'the_trade_desk' as const;

export type DatasourceType =
  | typeof DATASOURCE_TYPE_IQ
  | typeof DATASOURCE_TYPE_FACEBOOK
  | typeof DATASOURCE_TYPE_INSTAGRAM
  | typeof DATASOURCE_TYPE_ADFORM
  | typeof DATASOURCE_TYPE_OMNITURE
  | typeof DATASOURCE_TYPE_SPRINKLR
  | typeof DATASOURCE_TYPE_GOOGLE_ADS
  | typeof DATASOURCE_TYPE_GOOGLE_ADWORDS
  | typeof DATASOURCE_TYPE_GOOGLE_ANALYTICS_UA
  | typeof DATASOURCE_TYPE_GOOGLE_ANALYTICS_GA4
  | typeof DATASOURCE_TYPE_EXCEL
  | typeof DATASOURCE_TYPE_EXCEL_FTP
  | typeof DATASOURCE_TYPE_EXCEL_MAIL
  | typeof DATASOURCE_TYPE_TABMO
  | typeof DATASOURCE_TYPE_MEDIA_DESK
  | typeof DATASOURCE_TYPE_ZULU5
  | typeof DATASOURCE_TYPE_AMAZON_DSP
  | typeof DATASOURCE_TYPE_ZEMANTA
  | typeof DATASOURCE_TYPE_AUDIENCE_PROJECT
  | typeof DATASOURCE_TYPE_AUDIENCE_PROJECT_NEXT
  | typeof DATASOURCE_TYPE_LINKED_IN
  | typeof DATASOURCE_TYPE_BING
  | typeof DATASOURCE_TYPE_TIKTOK_ADS
  | typeof DATASOURCE_TYPE_TIKTOK_ACCOUNT
  | typeof DATASOURCE_TYPE_INDEED
  | typeof DATASOURCE_TYPE_FINANCE_DESK
  | typeof DATASOURCE_TYPE_PINTEREST
  | typeof DATASOURCE_TYPE_THE_TRADE_DESK;

export const DATASOURCE_TYPES: DatasourceType[] = [
  DATASOURCE_TYPE_IQ,
  DATASOURCE_TYPE_FACEBOOK,
  DATASOURCE_TYPE_INSTAGRAM,
  DATASOURCE_TYPE_ADFORM,
  DATASOURCE_TYPE_OMNITURE,
  DATASOURCE_TYPE_SPRINKLR,
  DATASOURCE_TYPE_GOOGLE_ADS,
  DATASOURCE_TYPE_GOOGLE_ADWORDS,
  DATASOURCE_TYPE_GOOGLE_ANALYTICS_UA,
  DATASOURCE_TYPE_GOOGLE_ANALYTICS_GA4,
  DATASOURCE_TYPE_EXCEL,
  DATASOURCE_TYPE_EXCEL_FTP,
  DATASOURCE_TYPE_EXCEL_MAIL,
  DATASOURCE_TYPE_TABMO,
  DATASOURCE_TYPE_MEDIA_DESK,
  DATASOURCE_TYPE_ZULU5,
  DATASOURCE_TYPE_AMAZON_DSP,
  DATASOURCE_TYPE_ZEMANTA,
  DATASOURCE_TYPE_AUDIENCE_PROJECT,
  DATASOURCE_TYPE_AUDIENCE_PROJECT_NEXT,
  DATASOURCE_TYPE_LINKED_IN,
  DATASOURCE_TYPE_BING,
  DATASOURCE_TYPE_TIKTOK_ADS,
  DATASOURCE_TYPE_TIKTOK_ACCOUNT,
  DATASOURCE_TYPE_INDEED,
  DATASOURCE_TYPE_FINANCE_DESK,
  DATASOURCE_TYPE_PINTEREST,
  DATASOURCE_TYPE_THE_TRADE_DESK
];
// DO NOT MODIFY THIS COMMENT autogenerated end

export function DatasourceTypeGetName(type: DatasourceType | string): string {
  switch (type) {
    case DATASOURCE_TYPE_IQ:
      return 'IQ Default';
    case DATASOURCE_TYPE_GOOGLE_ADS:
      return 'Google Ads';
    case DATASOURCE_TYPE_GOOGLE_ANALYTICS_GA4:
      return 'Google Analytics (GA4)';
    case DATASOURCE_TYPE_GOOGLE_ANALYTICS_UA:
      return 'Google Analytics (UA)';
    case DATASOURCE_TYPE_EXCEL_FTP:
      return '(S)FTP Import';
    case DATASOURCE_TYPE_EXCEL_MAIL:
      return 'Email Import';
    case DATASOURCE_TYPE_EXCEL:
      return 'Spreadsheet (Excel/CSV)';
    case DATASOURCE_TYPE_AMAZON_DSP:
      return 'Amazon DSP';
  }

  return type.replace(/_/g, ' ').replace(/(^| )[a-z]/g, (m) => m.toUpperCase());
}

export function DatasourceTypeGetStatus(type: DatasourceType | string): DatasourceTypeStatus {
  switch (type) {
    case DATASOURCE_TYPE_FINANCE_DESK:
    case DATASOURCE_TYPE_AUDIENCE_PROJECT_NEXT:
    case DATASOURCE_TYPE_PINTEREST:
      return DATASOURCE_TYPE_STATUS_BETA;
    case DATASOURCE_TYPE_OMNITURE:
      return DATASOURCE_TYPE_STATUS_DISABLED;
  }

  if (!DATASOURCE_TYPES.includes(type as DatasourceType)) {
    return DATASOURCE_TYPE_STATUS_DISABLED;
  }

  return DATASOURCE_TYPE_STATUS_STABLE;
}

export function isDatasourceType(value: DatasourceType | string | unknown): value is DatasourceType {
  return typeof value === 'string' && DATASOURCE_TYPES.some((type) => type === value);
}
export function assertDatasourceType(value: DatasourceType | string | unknown): asserts value is DatasourceType {
  if (!isDatasourceType(value)) {
    throw new Error(`Expected DatasourceType, got '${value}'.`);
  }
}
export function validDatasourceType(value: DatasourceType | string | unknown): DatasourceType {
  assertDatasourceType(value);
  return value;
}

export function getDatasourceTypeLabel(type: DatasourceType | string): string {
  switch (type) {
    case DATASOURCE_TYPE_IQ:
      return 'IQ Default';
    case DATASOURCE_TYPE_GOOGLE_ADS:
      return 'Google Ads';
    case DATASOURCE_TYPE_GOOGLE_ANALYTICS_GA4:
      return 'Google Analytics (GA4)';
    case DATASOURCE_TYPE_GOOGLE_ANALYTICS_UA:
      return 'Google Analytics (UA)';
    case DATASOURCE_TYPE_EXCEL_FTP:
      return '(S)FTP Import';
    case DATASOURCE_TYPE_EXCEL_MAIL:
      return 'Email Import';
    case DATASOURCE_TYPE_EXCEL:
      return 'Spreadsheet (Excel/CSV)';
    case DATASOURCE_TYPE_AMAZON_DSP:
      return 'Amazon DSP';
  }

  return type.replace(/_/g, ' ').replace(/(^| )[a-z]/g, (m) => m.toUpperCase());
}
