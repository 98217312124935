import { IqErrorId, isIqErrorId } from './iq-error-id';

export interface IqError {
  id: IqErrorId;
  type?: string | null;
  payload?: { [key: string]: any };
  message?: string | null;
  description?: string | null;
  url?: string | null;
  httpStatus?: number | null;
}

export function isIqError(err: unknown): err is IqError {
  return (
    typeof err === 'object' &&
    err !== null &&
    isIqErrorId((err as IqError).id) &&
    (!err.hasOwnProperty('type') || (err as IqError).type === null || typeof (err as IqError).type === 'string') &&
    (!err.hasOwnProperty('payload') ||
      (err as IqError).payload === null ||
      typeof (err as IqError).payload === 'object') &&
    (!err.hasOwnProperty('message') ||
      (err as IqError).message === null ||
      typeof (err as IqError).message === 'string') &&
    (!err.hasOwnProperty('description') ||
      (err as IqError).description === null ||
      typeof (err as IqError).description === 'string') &&
    (!err.hasOwnProperty('url') || (err as IqError).url === null || typeof (err as IqError).url === 'string') &&
    (!err.hasOwnProperty('httpStatus') ||
      (err as IqError).httpStatus === null ||
      typeof (err as IqError).httpStatus === 'number')
  );
}

export function iqErrorToErrorClass(iqError: IqError): Error & IqError {
  if (iqError instanceof Error) {
    throw iqError;
  }
  const err: Error & IqError = new Error(iqError.message ?? 'An error occurred.') as Error & IqError;
  err.id = iqError.id;
  err.type = iqError.type;
  err.payload = iqError.payload;
  err.description = iqError.description;
  err.url = iqError.url;
  err.httpStatus = iqError.httpStatus;
  return err;
}
