import { Component, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import {
  Translation,
  TranslationsService,
} from "src/app/_services/translations.service";
import { ToastService } from "src/app/_services/toast.service";
import { FormControl, FormGroup } from "@angular/forms";
import { SecurityService } from "src/app/_services/api/security.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["../assets/scss/front-sites.component.scss"],
})
export class PasswordResetComponent implements OnInit {
  pwdResetForm: FormGroup<{ username: FormControl<string | null> }>;
  public passwordResetInvalid: boolean;
  public loading: boolean;
  public success: boolean;

  constructor(
    private _securityService: SecurityService,
    private _toastService: ToastService,
    private _translationsService: TranslationsService
  ) {}

  ngOnInit() {
    this.pwdResetForm = new FormGroup<{ username: FormControl<string | null> }>(
      {
        username: new FormControl<string>("", [
          Validators.required,
          Validators.email,
        ]),
      }
    );
  }

  async onSubmit(): Promise<void> {
    this.passwordResetInvalid = false;
    if (this.pwdResetForm.valid) {
      const username = this.pwdResetForm.value.username;
      if (username === null || username === undefined || username === "") {
        return;
      }

      this.loading = true;
      firstValueFrom(this._securityService.passwordResetEmail(username))
        .then(() => {
          this.success = true;
          this.loading = false;
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this._toastService.warning(
              new Translation("pages.login.toastr.conn_failed.message"),
              new Translation("pages.login.toastr.conn_failed.title")
            );
          } else {
            this.success = true;
          }
          this.loading = false;
        });
    } else {
      const hints: string[] = [];
      if (!this.pwdResetForm.controls["username"].valid) {
        await this._translationsService
          .trans("pages.login.hints.username")
          .then((translation: string) => {
            hints.push(translation);
          });
      }
      if (hints.length > 0) {
        this._toastService.warning(
          new Translation("pages.login.hints.toastr.message"),
          new Translation("pages.pwd_reset.toastr.title"),
          undefined,
          { disableTimeOut: true }
        );
      }
    }
  }
}
