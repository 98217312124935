import { Injectable, isDevMode } from "@angular/core";
import { Subject } from "rxjs";
import { TranslationsService } from "./translations.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class StatusNotificationService {
  private serverErrorSubject: Subject<void> = new Subject<void>();
  private networkErrorSubject: Subject<void> = new Subject<void>();

  constructor(
    private _matSnackBar: MatSnackBar,
    private _translationsService: TranslationsService
  ) {
    if (isDevMode()) {
      let serverErrorMessageRecentlyShown = false;
      this.serverErrorSubject.subscribe(async () => {
        if (serverErrorMessageRecentlyShown) {
          return;
        }
        this._matSnackBar.open(
          await this._translationsService.trans(
            "status_notification.server_error"
          ),
          undefined,
          { duration: 3000 }
        );
        setTimeout(() => {
          serverErrorMessageRecentlyShown = false;
        }, 10000);
        serverErrorMessageRecentlyShown = true;
      });
    }

    let networkErrorMessageRecentlyShown = false;
    this.networkErrorSubject.subscribe(async () => {
      if (networkErrorMessageRecentlyShown) {
        return;
      }
      this._matSnackBar.open(
        await this._translationsService.trans(
          "status_notification.network_error"
        ),
        undefined,
        { duration: 3000 }
      );
      setTimeout(() => {
        networkErrorMessageRecentlyShown = false;
      }, 10000);
      networkErrorMessageRecentlyShown = true;
    });
  }

  public notifyServerError(): void {
    this.serverErrorSubject.next();
  }

  public notifyNetworkError(): void {
    this.networkErrorSubject.next();
  }
}
