import { NgModule } from "@angular/core";
import { AsyncPipe, CommonModule, DecimalPipe } from "@angular/common";
import { MaterialModule } from "./material.module";
import { ProgressBarComponent } from "src/app/shared/misc/progress-bar.component";
import { TreeViewComponent } from "src/app/shared/misc/tree-view.component";
import { AdminIconComponent } from "src/app/shared/misc/admin-icon.component";
import { DndDirective } from "src/app/_directives/dnd.directive";
import { UserLabelComponent } from "src/app/shared/misc/user-label.component";
import { TimeComponent } from "src/app/shared/misc/time.component";
import { DateComponent } from "src/app/shared/misc/date.component";
import { InputDirectivesModule } from "src/app/shared/input-directives/input-directives.module";
import { SortByPipe } from "src/app/_pipes/sortBy.pipe";
import { NumberWithLocalePipe } from "src/app/_pipes/number-with-locale.pipe";
import { NumberBytesWithLocalePipe } from "src/app/_pipes/number-bytes-with-locale.pipe";
import { IdComponent } from "src/app/shared/misc/id.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { CopyableModule } from "src/app/_directives/copyable.module";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    InputDirectivesModule,
    ClipboardModule,
    CopyableModule,
  ],
  exports: [
    ProgressBarComponent,
    TreeViewComponent,
    AdminIconComponent,
    UserLabelComponent,
    TimeComponent,
    DateComponent,
    DndDirective,
    SortByPipe,
    NumberWithLocalePipe,
    NumberBytesWithLocalePipe,
    IdComponent,
  ],
  declarations: [
    ProgressBarComponent,
    TreeViewComponent,
    AdminIconComponent,
    UserLabelComponent,
    TimeComponent,
    DateComponent,
    DndDirective,
    SortByPipe,
    NumberWithLocalePipe,
    NumberBytesWithLocalePipe,
    IdComponent,
  ],
  providers: [DecimalPipe, AsyncPipe],
})
export class SharedModule {}
