import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NavbarComponent } from "./navbar.component";
import { MatButtonModule } from "@angular/material/button";
import { MaterialModule } from "src/app/_modules/material.module";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
@NgModule({
  imports: [RouterModule, CommonModule, MatButtonModule, MaterialModule],
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NavbarModule {}
