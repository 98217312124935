<div [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div class="mytoast-body">
        <div class="mytoast-icon" *ngIf="customIcon">
            <mat-icon>{{customIcon}}</mat-icon>
        </div>
        <div class="mytoast-text">
            <b *ngIf="config.custom.title">{{ config.custom.title|translatable }}</b>
            <span role="alert">{{ config.custom.message|translatable }}</span>
        </div>
        <span class="mytoast-close">&times;</span>
    </div>
    <div class="mytoast-buttons">
        <ng-container *ngFor="let button of config.custom.buttons">
            <button *ngIf="button.icon" mat-mini-fab color="accent" (click)="action($event, button.action)">
                <mat-icon>{{button.icon}}</mat-icon>
            </button>
            <button *ngIf="!button.icon" mat-raised-button color="accent" (click)="action($event, button.action)">
                {{button.label|translatable}}
            </button>
        </ng-container>
    </div>
</div>
