import { DATASOURCE_TYPE_INDEED, DATASOURCE_TYPE_OMNITURE, isDatasourceType } from './datasource-type';
import type { DatasourceType } from './datasource-type';

export const DATASOURCE_TYPE_STATUS_ALPHA = 'alpha';
export const DATASOURCE_TYPE_STATUS_BETA = 'beta';
export const DATASOURCE_TYPE_STATUS_DEPRECATED = 'deprecated';
export const DATASOURCE_TYPE_STATUS_DISABLED = 'disabled';
export const DATASOURCE_TYPE_STATUS_STABLE = 'stable';

export type DatasourceTypeStatus =
  | typeof DATASOURCE_TYPE_STATUS_ALPHA
  | typeof DATASOURCE_TYPE_STATUS_BETA
  | typeof DATASOURCE_TYPE_STATUS_DEPRECATED
  | typeof DATASOURCE_TYPE_STATUS_DISABLED
  | typeof DATASOURCE_TYPE_STATUS_STABLE;

export function isDatasourceTypeStatus(value: DatasourceTypeStatus | string | unknown): value is DatasourceTypeStatus {
  return (
    value === DATASOURCE_TYPE_STATUS_ALPHA ||
    value === DATASOURCE_TYPE_STATUS_BETA ||
    value === DATASOURCE_TYPE_STATUS_DEPRECATED ||
    value === DATASOURCE_TYPE_STATUS_DISABLED ||
    value === DATASOURCE_TYPE_STATUS_STABLE
  );
}

export function createDatasourceTypeStatusFromDatasourceType(type: DatasourceType | string): DatasourceTypeStatus {
  switch (type) {
    case DATASOURCE_TYPE_INDEED:
      return DATASOURCE_TYPE_STATUS_ALPHA;
    case DATASOURCE_TYPE_OMNITURE:
      return DATASOURCE_TYPE_STATUS_DISABLED;
  }

  if (!isDatasourceType(type)) {
    return DATASOURCE_TYPE_STATUS_DISABLED;
  }

  return DATASOURCE_TYPE_STATUS_STABLE;
}
