import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { CurrentTenantService } from "./current-tenant.service";
import { SecurityService } from "./api/security.service";
import { Tenant, TenantService } from "./api/tenant.service";
import { TenantEvent, TenantEventsService } from "./tenant-events.service";
import { Translation } from "./translations.service";
import { ToastService } from "./toast.service";
import { PersistentValueStoreService } from "src/app/_store/generic/persistent-value.store.service";
import { firstValueFrom, from, Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IndexGuardService } from "src/app/_guards/index-guard.service";
import { ErrorHandlerService } from "./error-handler.service";
import { TenantId } from "src/../../src/types/tenant-id";
import { isUuid } from "src/../../src/types/uuid";
import { AuthUser } from "../_models/auth-user";
import { AccessToken } from "../_models/access-token";
import { AuthUserRepositoryService } from "./repository/auth-user-repository.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private static readonly REFRESH_TOKEN_STORAGE_KEY = "iq.auth.refreshToken";
  private static readonly REMOVE_ACCESS_TOKEN_X_SECONDS_BEFORE_EXPIRED = 1;
  private static readonly REFRESH_ACCESS_TOKEN_X_SECONDS_BEFORE_EXPIRED = 60;
  private static readonly REFRESH_ACCESS_TOKEN_MAX_PERCENTAGE_BEFORE_EXPIRED = 0.5;
  private static readonly REFRESH_ACCESS_TOKEN_IF_AT_LEAST_EXPIRE_IN = 60;
  private _refreshTokenStore: PersistentValueStoreService<string>;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _currentTenantService: CurrentTenantService,
    private _authUserRepositoryService: AuthUserRepositoryService,
    private _securityService: SecurityService,
    private _tenantService: TenantService,
    private _eventsService: TenantEventsService,
    private _toastService: ToastService,
    private _errorHandlerService: ErrorHandlerService
  ) {
    this._refreshTokenStore =
      PersistentValueStoreService.createStringFromLocalStorage(
        AuthService.REFRESH_TOKEN_STORAGE_KEY
      );

    this._eventsService
      .eventsFor("UserChanged")
      .subscribe((event: TenantEvent<"UserChanged">) => {
        const userId = event.payload.userId;
        if (
          isUuid(userId) &&
          this._authUserRepositoryService.current?.userId === userId
        ) {
          this._refreshAccessToken()
            .then(() => {
              this._toastService.info(
                new Translation("services.auth.user_changed.info.message"),
                new Translation("services.auth.user_changed.info.title")
              );
            })
            .catch(() => {
              this._toastService.danger(
                new Translation("services.auth.user_changed.danger.message"),
                new Translation("services.auth.user_changed.danger.title")
              );
            });
        }
      });
  }

  public login(
    loginData: {
      username: string;
      password: string;
    },
    redirect: boolean = true,
    redirectDelay: number = 2
  ): Promise<boolean> {
    if (loginData.username.trim() === "" || loginData.password.trim() === "") {
      return Promise.resolve(false);
    }

    const loginPromise = firstValueFrom(
      this._securityService.getAccessTokenFromUserPassword(
        null,
        loginData.username,
        loginData.password
      )
    )
      .then((response) => {
        return this._addAuthTokenResponse(response);
      })
      .then(() => true)
      .catch((err) => {
        console.error(err);
        return false;
      });

    if (!redirect) {
      return loginPromise;
    }

    return loginPromise.then((success) => {
      if (!success) {
        return false;
      }

      setTimeout(() => {
        this._router.navigateByUrl(
          AuthService.getAfterSuccessfulLoginUrl(
            this._router,
            this._route.snapshot,
            this._currentTenantService,
            () => this._authUserRepositoryService.currentAuthUser
          )
        );
      }, 1000 * redirectDelay);

      return true;
    });
  }

  public static getAfterSuccessfulLoginUrl(
    router: Router,
    route: ActivatedRouteSnapshot,
    currentTenantService: CurrentTenantService,
    authUserCallback: () => AuthUser | null
  ): UrlTree {
    const rawReturnUrl: any = route.queryParams.returnUrl;
    let returnUrl: string | null =
      typeof rawReturnUrl === "string" ? rawReturnUrl : null;
    if (returnUrl && returnUrl.trim() === "") {
      returnUrl = null;
    }
    if (returnUrl) {
      return router.parseUrl(returnUrl);
    }
    return IndexGuardService.getIndexRedirectUrl(
      router,
      currentTenantService,
      authUserCallback
    );
  }

  public refreshLoginAndReload(
    authTokenResponsePromise: Promise<{
      accessToken: AccessToken;
      refreshToken: string | null;
    }>
  ): Promise<void> {
    return authTokenResponsePromise
      .then((response) => {
        return this._addAuthTokenResponse(response);
      })
      .then(() => {
        window.location.reload();
      });
  }

  public async switchTenant(tenant: Tenant | TenantId): Promise<void> {
    if (!tenant) {
      console.debug("AuthService: Missing parameter tenant to switchTenant");
      return Promise.reject("Missing parameter tenant to switch Tenant");
    }
    const tenantId = typeof tenant === "string" ? tenant : tenant.id;
    if (this._currentTenantService.currentTenantId === tenantId) {
      return Promise.resolve();
    }

    const authUser = this._authUserRepositoryService.currentAuthUser;
    if (!authUser) {
      console.debug("AuthService: Missing refreshToken for login");
      return Promise.reject("Missing refreshToken for login");
    }

    if (!authUser?.iqUser?.canAnyForTenant(tenantId)) {
      throw new Error("Switching to tenant failed.");
    }

    const tenantObj: Tenant =
      typeof tenant === "string"
        ? await firstValueFrom(this._tenantService.getTenant(tenantId))
        : tenant;

    this._currentTenantService.updateTenant(tenantObj);
  }

  public async logout(returnUrl: string | null = null): Promise<void> {
    returnUrl =
      returnUrl === null || returnUrl === "/" || returnUrl.trim() === ""
        ? null
        : returnUrl;

    const refreshToken = this._refreshTokenStore.current;
    if (refreshToken) {
      // do not trigger anything according to changed refresh-token, because we are going to reload the page
      this._refreshTokenStore.updateValueOnlyInStorage(null);
      // do not reset the access-token, because we are going to reload the page

      try {
        await firstValueFrom(
          this._securityService.deleteMyRefreshTokenByToken(refreshToken)
        );
      } catch (e) {
        await this._errorHandlerService.handle(e);
      }
    }

    // force fresh page to be loaded to clean any cached data in js
    window.location.href = this._router
      .createUrlTree(
        ["auth", "login"],
        returnUrl !== null ? { queryParams: { returnUrl: returnUrl } } : {}
      )
      .toString();
  }

  private async _addAccessToken(accessToken: AccessToken): Promise<AuthUser> {
    console.debug("AuthService._addAccessToken()");

    const expireIn: number = accessToken.getExpiresInSeconds();
    if (expireIn <= AuthService.REMOVE_ACCESS_TOKEN_X_SECONDS_BEFORE_EXPIRED) {
      throw new Error(
        "AuthService: unable to add expired access-token, expires in seconds: " +
          expireIn
      );
    }

    const authUser = await this._authUserRepositoryService.updateAccessToken(
      accessToken
    );

    setTimeout(() => {
      console.debug(
        "AuthService: check for expired admin access-token, expires in seconds:",
        expireIn
      );
      if (this._authUserRepositoryService.removeAccessToken(accessToken)) {
        console.debug("AuthService: expired access-token found and removed");
      }
    }, (expireIn - AuthService.REMOVE_ACCESS_TOKEN_X_SECONDS_BEFORE_EXPIRED) * 1000);

    if (expireIn > AuthService.REFRESH_ACCESS_TOKEN_IF_AT_LEAST_EXPIRE_IN) {
      const refreshInMs = Math.round(
        Math.min(
          expireIn *
            AuthService.REFRESH_ACCESS_TOKEN_MAX_PERCENTAGE_BEFORE_EXPIRED,
          expireIn - AuthService.REFRESH_ACCESS_TOKEN_X_SECONDS_BEFORE_EXPIRED
        ) * 1000
      );
      setTimeout(() => {
        const refreshToken = this._refreshTokenStore.current;
        if (!refreshToken) {
          return;
        }
        if (
          this._authUserRepositoryService.currentAuthUser?.accessToken
            .accessToken === accessToken.accessToken
        ) {
          console.debug("AuthService: update access-token before expiration");
          this._refreshAccessToken()
            .then(() => {
              console.debug(
                "AuthService: update access-token before expiration done"
              );
            })
            .catch((e) => {
              console.debug(
                "AuthService: update access-token before expiration failed",
                e
              );
            });
        }
      }, refreshInMs);
    }

    return authUser;
  }

  public initLogin(): Observable<AuthUser | null> {
    const user = this._authUserRepositoryService.currentAuthUser;
    if (user !== null) {
      return of(user);
    }

    const refreshToken = this._refreshTokenStore.current;
    if (!refreshToken) {
      return of(null);
    }

    return this._tryRefreshAccessToken().pipe(catchError(() => of(null)));
  }

  public initLoginAndSwitchTenant(
    tenantId: TenantId
  ): Observable<AuthUser | null> {
    const authUser = this.initLogin();
    if (tenantId) {
      return authUser.pipe(
        switchMap((user) =>
          from(this.switchTenant(tenantId)).pipe(map(() => user))
        )
      );
    }

    return authUser;
  }

  private _tryRefreshAccessToken(): Observable<AuthUser | null> {
    const refreshToken = this._refreshTokenStore.current;
    if (!refreshToken) {
      return of(null);
    }

    return this._securityService
      .getAccessTokenFromRefreshToken(null, refreshToken)
      .pipe(
        switchMap((response) => {
          return this._addAuthTokenResponse(response);
        })
      );
  }

  private _refreshAccessToken(): Promise<AuthUser> {
    return firstValueFrom(this._tryRefreshAccessToken()).then(
      (user: AuthUser | null) => {
        if (user === null) {
          throw new Error(
            "Unable to refresh access-token without refresh-token"
          );
        }
        return user;
      }
    );
  }

  private _addAuthTokenResponse(response: {
    accessToken: AccessToken;
    refreshToken: string | null;
  }): Promise<AuthUser> {
    const newRefreshToken = response.refreshToken;
    if (newRefreshToken) {
      this._refreshTokenStore.updateValue(newRefreshToken);
    }

    return this._addAccessToken(response.accessToken);
  }
}
