import { StringScope, StringScopes } from "../../../../src/types/string-scope";
import { objectMap } from "../../../../src/types/helpers";

export class Scope<T extends string = StringScope> {
  private constructor(private readonly key: T) {}

  public static fromString<T extends string>(key: T): Scope<T> {
    return new Scope<T>(key);
  }

  public static createMany(
    scopes: StringScope | Scope | (StringScope | Scope)[]
  ): Scope[] {
    if (!Array.isArray(scopes)) {
      scopes = [scopes];
    }
    return scopes.map((s) => (s instanceof Scope ? s : Scope.fromString(s)));
  }

  public toString(): T {
    return this.key;
  }

  public isSpecialScope(): boolean {
    return this.key.split(".").length === 1;
  }

  public isTenantIndependent(): boolean {
    return this.equals(Scopes.TENANT_INDEPENDENT);
  }

  public isAdmin(): boolean {
    return this.equals(Scopes.ALL);
  }

  public isOffline(): boolean {
    return this.equals(Scopes.OFFLINE);
  }

  public equals(other: Scope<string>): boolean {
    return this.key === other.key;
  }

  public allows(other: Scope<string>): boolean {
    if (other.equals(this)) {
      return true;
    }

    if (other.isSpecialScope()) {
      return false;
    }

    if (this.isAdmin()) {
      return true;
    }

    const parts = other.key.split(".");
    for (let i = 1; i < parts.length; i++) {
      const possibleMorePrivilegedScope = parts.slice(0, i).join(".") + ".all";
      if (this.equals(Scope.fromString(possibleMorePrivilegedScope))) {
        return true;
      }
    }

    return false;
  }
}

type ScopesObjType = {
  [key in keyof typeof StringScopes]: Scope<(typeof StringScopes)[key]>;
};

function mapScope<K extends keyof typeof StringScopes>(
  _key: K,
  valueA: (typeof StringScopes)[K]
): ScopesObjType[K] {
  return Scope.fromString<(typeof StringScopes)[K]>(valueA) as ScopesObjType[K];
}

export const Scopes: ScopesObjType = objectMap<
  keyof typeof StringScopes,
  typeof StringScopes,
  ScopesObjType
>(StringScopes, mapScope);
export type ScopeString = StringScope;
