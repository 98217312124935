import { BehaviorSubject, Observable } from "rxjs";

export class PersistentValueStoreService<T> {
  private _value: BehaviorSubject<T | null>;
  private _observable: Observable<T | null>;

  private constructor(
    private _loadValue: () => T | null,
    private _storeValue: (value: T | null) => void
  ) {
    this._value = new BehaviorSubject<T | null>(this._loadValue());
    this._observable = this._value.asObservable();
  }

  public static createFromLocalStorage<T>(
    storageKey: string,
    loadValue: (localStorageValue: string | null) => T | null,
    storeValue: (value: T | null) => string | null
  ) {
    return new PersistentValueStoreService<T>(
      (): T | null => loadValue(window.localStorage.getItem(storageKey)),
      (value: T | null): void => {
        const localStorageValue = storeValue(value);
        if (localStorageValue === null) {
          window.localStorage.removeItem(storageKey);
          return;
        }

        window.localStorage.setItem(storageKey, localStorageValue);
      }
    );
  }

  public static createBooleanFromLocalStorage(storageKey: string) {
    return PersistentValueStoreService.createFromLocalStorage<boolean>(
      storageKey,
      (localStorageValue: string | null): boolean | null =>
        localStorageValue === null ? null : localStorageValue === "true",
      (value: boolean | null) =>
        value === null ? null : value ? "true" : "false"
    );
  }

  public static createStringFromLocalStorage(storageKey: string) {
    return PersistentValueStoreService.createFromLocalStorage<string>(
      storageKey,
      (localStorageValue: string | null): string | null => localStorageValue,
      (value: string | null) => value
    );
  }

  public get observable(): Observable<T | null> {
    return this._observable;
  }

  public get current(): T | null {
    return this._value.getValue();
  }

  public isLoaded(): boolean {
    return this._value.getValue() !== null;
  }

  public updateValue(value: T | null) {
    this._storeValue(value);
    this._value.next(value);
  }

  public initValue(value: T | null) {
    if (this.isLoaded()) {
      return;
    }

    this.updateValue(value);
  }

  public clearValue() {
    this.updateValue(null);
  }

  public updateValueOnlyInStorage(value: T | null): void {
    this._storeValue(value);
  }
}
