import { Component, AfterViewInit } from "@angular/core";
import { validDefined } from "src/../../src/types/defined";
import { validDefinedNotNull } from "src/../../src/types/defined-not-null";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"],
})
export class AuthLayoutComponent implements AfterViewInit {
  imageArray1 = [
    "./assets/img/iq/backgrounds/img1.jpg",
    "./assets/img/iq/backgrounds/img2.jpg",
    "./assets/img/iq/backgrounds/img3.jpg",
    "./assets/img/iq/backgrounds/img4.jpg",
    "./assets/img/iq/backgrounds/img5.jpg",
  ];
  imageArray2 = [
    "./assets/img/iq/backgrounds/img6.jpg",
    "./assets/img/iq/backgrounds/img7.jpg",
    "./assets/img/iq/backgrounds/img8.jpg",
    "./assets/img/iq/backgrounds/img9.jpg",
    "./assets/img/iq/backgrounds/img10.jpg",
  ];
  imageArray3 = [
    "./assets/img/iq/backgrounds/img11.jpg",
    "./assets/img/iq/backgrounds/img12.jpg",
    "./assets/img/iq/backgrounds/img13.jpg",
    "./assets/img/iq/backgrounds/img14.jpg",
    "./assets/img/iq/backgrounds/img15.jpg",
  ];
  imageArray4 = [
    "./assets/img/iq/backgrounds/img16.jpg",
    "./assets/img/iq/backgrounds/img17.jpg",
    "./assets/img/iq/backgrounds/img18.jpg",
    "./assets/img/iq/backgrounds/img19.jpg",
    "./assets/img/iq/backgrounds/img20.jpg",
  ];

  constructor() {}

  ngAfterViewInit() {
    const imageArray = [
      this.imageArray1,
      this.imageArray2,
      this.imageArray3,
      this.imageArray4,
    ];
    const randomArray = validDefined(
      imageArray[Math.floor(Math.random() * imageArray.length)]
    );

    this.loadImages(randomArray);

    const elMainBodyWrapper: HTMLElement = validDefinedNotNull(
      document.getElementsByClassName("iq-auth-layout")[0]
    ) as HTMLElement;

    let i = 0;

    (function loginPageBG() {
      elMainBodyWrapper.style.backgroundImage = "url(" + randomArray[i] + ")";
      i = i + 1;
      if (i === randomArray.length) {
        i = 0;
      }
      setTimeout(loginPageBG, 10000);
    })();
  }

  loadImages(randomArray) {
    const image = new Image();
    for (let i = 0; i < randomArray.length; i++) {
      image.useMap = randomArray[i];
      const img = document.createElement("img");
      img.setAttribute("src", image.useMap);
      img.setAttribute("style", "display:none;");
      document.body.appendChild(img);
    }
  }
}
