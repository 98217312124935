import { assertString } from './string';

type CommonChar =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z'
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '+'
  | '-'
  | '_'
  | '.'
  | '@'
  | '/'
  | '='
  | '&'
  | '%'
  | '?'
  | '!'
  | '*'
  | '('
  | ')'
  | '['
  | ']'
  | '{'
  | '}'
  | '|'
  | '\\'
  | '<'
  | '>';
export type StringNotEmpty = string & ({ __iq_type_string_not_empty: never } | `${CommonChar}${string}`);

export function isStringNotEmpty(value: StringNotEmpty | string | unknown): value is StringNotEmpty {
  return typeof value === 'string' && value !== '';
}

export function assertStringNotEmpty<T>(value: StringNotEmpty | T): asserts value is StringNotEmpty & T {
  if (!isStringNotEmpty(value)) {
    assertString(value);
    throw new Error("Expected not empty string, got '" + value + "'.");
  }
}

export function validStringNotEmpty<T>(value: StringNotEmpty | T): StringNotEmpty & T {
  assertStringNotEmpty(value);
  return value;
}
