import { Injectable } from "@angular/core";
import { ErrorNormalizerService } from "./error-normalizer.service";

@Injectable({
  providedIn: "root",
})
export class ErrorTraceService {
  private errors: (string | Error)[] = [];
  private readonly MAX_LOGS = 20;

  constructor(private _normalizer: ErrorNormalizerService) {}

  public async add(err: any): Promise<void> {
    const error = await this._normalizer.normalize(err);
    if (error instanceof Error) {
      this.addNative(error);
      return;
    }

    this.addId(error.id);
  }

  public addId(errorId: string): void {
    this.errors.push(errorId);
    if (this.errors.length > this.MAX_LOGS) {
      this.errors.shift();
    }
  }

  public addNative(error: Error): void {
    this.errors.push(error);
    if (this.errors.length > this.MAX_LOGS) {
      this.errors.shift();
    }
  }

  public getErrors(): (string | Error)[] {
    return this.errors.reverse();
  }
}
