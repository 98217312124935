<form [formGroup]="pwdResetForm" (ngSubmit)="onSubmit()">
    <app-card
            [headerTitle]="'pages.pwd_reset.new_pwd'|trans"
            actionsPosition="center"
            [loading]="loading">
        <div class="col-10 offset-1">
            <mat-form-field appearance="outline">
                <mat-label>{{'pages.login.input.password'|trans}}</mat-label>
                <input matInput type="password"
                       placeholder="{{'pages.login.input.password'|trans}}"
                       [formGroup]="pwdResetForm"
                       [formControlName]="'password'" autofocus>
            </mat-form-field>
        </div>

        <button app-card-footer-action
                mat-raised-button
                type="submit"
                color="primary">
            {{'pages.pwd_reset.reset'|trans}}
        </button>

        <div app-card-footer-last class="back-to-login">
            <a [routerLink]="['/auth/login']" [hidden]="loading" class="text-center">
                {{'pages.pwd_reset.back'|trans}}
            </a>

            <div [hidden]="loading" class="mt-3">
                <a class="m-1" [attr.href]="'app.terms.href'|trans">{{'app.terms.label'|trans}}</a>
                <a class="m-1" [attr.href]="'app.privacy_notice.href'|trans">{{'app.privacy_notice.label'|trans}}</a>
            </div>
        </div>
    </app-card>
</form>