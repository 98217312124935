import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { IqUser } from "src/app/_models/iq-user";
import { SecurityService } from "../api/security.service";
import { AccessToken } from "../../_models/access-token";
import { AuthUser } from "../../_models/auth-user";
import { AuthUserStoreService } from "../../_store/auth-user-store.service";
import { AuthUserReadonlyRepositoryService } from "./auth-user-readonly-repository.service";
import { CurrentTenantService } from "../current-tenant.service";

@Injectable({
  providedIn: "root",
})
export class AuthUserRepositoryService extends AuthUserReadonlyRepositoryService {
  constructor(
    _authUserStoreService: AuthUserStoreService,
    _currentTenantService: CurrentTenantService,
    protected _securityService: SecurityService
  ) {
    super(_authUserStoreService, _currentTenantService);
  }

  public async updateAccessToken(value: AccessToken): Promise<AuthUser>;
  public async updateAccessToken(value: null): Promise<null>;
  public async updateAccessToken(
    value: AccessToken | null
  ): Promise<AuthUser | null> {
    if (value === null) {
      this._authUserStoreService.updateValue(null);
      return null;
    }

    return firstValueFrom(this._securityService.getUserMe(value))
      .catch((err) => {
        this._authUserStoreService.updateValue(null);
        throw err;
      })
      .then((user) => {
        const authUser = new AuthUser(value, user, IqUser.fromUser(user));
        this._authUserStoreService.updateValue(authUser);
        return authUser;
      });
  }

  public removeAccessToken(accessToken: AccessToken): boolean {
    if (
      this._authUserStoreService.current?.accessToken?.accessToken ===
      accessToken.accessToken
    ) {
      this._authUserStoreService.updateValue(null);
      return true;
    }

    return false;
  }
}
