import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorHandlerService } from "src/app/_services/error-handler.service";
import {
  Translation,
  TranslationsService,
} from "src/app/_services/translations.service";
import { ToastService } from "src/app/_services/toast.service";
import { FormControl, FormGroup } from "@angular/forms";
import { SecurityService } from "src/app/_services/api/security.service";
import { Validators } from "@angular/forms";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-password-reset-password",
  templateUrl: "./password-reset-password.component.html",
  styleUrls: ["../assets/scss/front-sites.component.scss"],
})
export class PasswordResetPasswordComponent implements OnInit {
  private code: string | null = null;
  public pwdResetForm: FormGroup<{ password: FormControl<string | null> }>;
  public passwordResetInvalid: boolean;
  public loading: boolean;
  public success: boolean;

  constructor(
    private _router: Router,
    private _securityService: SecurityService,
    private _route: ActivatedRoute,
    private _errorHandlerService: ErrorHandlerService,
    private _translationsService: TranslationsService,
    private _toastService: ToastService
  ) {
    this.pwdResetForm = new FormGroup<{ password: FormControl<string | null> }>(
      {
        password: new FormControl<string>("", [Validators.required]),
      }
    );
  }

  ngOnInit() {
    this._route.queryParamMap.subscribe((queryParams) => {
      this.code = queryParams.get("code") ?? null;
    });

    const oobCode = this.code;
    if (!oobCode) {
      this._router.navigate(["auth/login"]);
      return;
    }

    firstValueFrom(this._securityService.checkOObCode(oobCode)).catch(
      (error) => {
        this._toastService.warning(
          error.error["error"],
          new Translation("pages.pwd_reset.toastr.title"),
          undefined,
          { disableTimeOut: true }
        );
        this._router.navigate(["auth/login"]);
      }
    );
  }

  loadImages(randomArray) {
    const image = new Image();
    for (let i = 0; i < randomArray.length; i++) {
      image.useMap = randomArray[i];
      const img = document.createElement("img");
      img.setAttribute("src", image.useMap);
      img.setAttribute("style", "display:none;");
      document.body.appendChild(img);
    }
  }

  async onSubmit() {
    const oobCode = this.code;
    if (!oobCode) {
      return;
    }

    this.passwordResetInvalid = false;
    if (this.pwdResetForm.valid) {
      const password = this.pwdResetForm.value.password;
      if (password === null || password === undefined || password === "") {
        return;
      }

      this.loading = true;
      firstValueFrom(this._securityService.resetPassword(oobCode, password))
        .then(() => {
          this.success = true;
          this._toastService.success(
            new Translation("pages.pwd_reset.pwd.success.message"),
            new Translation("pages.pwd_reset.pwd.success.title")
          );
          this._router.navigate(["auth/login"]);
        })
        .catch((error) => {
          this.loading = false;
          this.passwordResetInvalid = true;
          this._errorHandlerService.handle(error);
        });
    } else {
      const hints: string[] = [];
      if (!this.pwdResetForm.controls["password"].valid) {
        await this._translationsService
          .trans("pages.login.hints.password")
          .then((translation: string) => {
            hints.push(translation);
          });
      }
      if (hints.length > 0) {
        this._toastService.warning(
          new Translation("pages.login.hints.toastr.message") +
            hints.join(
              (await this._translationsService.resolvePromise(
                new Translation("global.and")
              )) ?? " "
            ),
          new Translation("pages.pwd_reset.toastr.title"),
          undefined,
          { disableTimeOut: true }
        );
      }
    }
  }
}
