import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";

@Injectable({
  providedIn: "root",
})
export abstract class UserLanguageStoreService {
  private _observable: Observable<string | null>;

  constructor(private _userStore: AuthUserReadonlyRepositoryService) {
    this._observable = this._userStore.observable
      .pipe(map((user) => user && user.language))
      .pipe(shareReplay(1));
  }

  public get observable(): Observable<string | null> {
    return this._observable;
  }
}
