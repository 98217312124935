import { Component, OnDestroy, OnInit } from "@angular/core";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";
import { RefreshTokenStoreService } from "src/app/_store/refresh-token-store.service";
import { map, takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/_services/auth.service";
import { Translation } from "src/app/_services/translations.service";
import { ToastService } from "src/app/_services/toast.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-session-expired-cmp",
  templateUrl: "session-expired.component.html",
  styleUrls: ["session-expired.component.scss"],
})
export class SessionExpiredComponent implements OnInit, OnDestroy {
  protected _onDestroy = new Subject<void>();

  public isLoggedIn: Observable<boolean>;
  public refreshTokenAvailable: Observable<boolean>;
  public lastUserEmail: string | null = null;
  public refreshLoginInProgress: boolean = false;
  public passwordValue: string = "";

  constructor(
    private _authService: AuthService,
    private _authUserStore: AuthUserReadonlyRepositoryService,
    private _refreshTokenStore: RefreshTokenStoreService,
    private _toastService: ToastService,
    private _router: Router
  ) {
    this.isLoggedIn = this._authUserStore.observable.pipe(
      map((user) => !!user)
    );
    this.refreshTokenAvailable = this._refreshTokenStore.observable.pipe(
      map((token) => !!token)
    );
  }

  ngOnInit() {
    this._authUserStore.observable
      .pipe(takeUntil(this._onDestroy))
      .subscribe((user) => {
        if (user && user.userEmail) {
          this.lastUserEmail = user.userEmail;
        }
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public refreshLogin(): void {
    if (this.refreshLoginInProgress) {
      return;
    }

    this.refreshLoginInProgress = true;
    firstValueFrom(this._authService.initLogin()).finally(() => {
      this.refreshLoginInProgress = false;
    });
  }

  public login(): void {
    if (this.refreshLoginInProgress) {
      return;
    }

    const username = this.lastUserEmail;
    if (username === null) {
      return;
    }

    this.refreshLoginInProgress = true;
    this._authService
      .login(
        {
          username: username,
          password: this.passwordValue,
        },
        false
      )
      .then((success) => {
        if (success) {
          this._toastService.success(
            new Translation("app.message.login.success"),
            new Translation("app.message.login.success.title")
          );
        } else {
          this._toastService.danger(
            new Translation("app.message.login.failure"),
            new Translation("app.message.login.failure.title")
          );
        }
      })
      .finally(() => {
        this.refreshLoginInProgress = false;
      });
  }

  public async logout(): Promise<void> {
    if (this.refreshLoginInProgress) {
      return;
    }

    await this._authService.logout(this._router.url);
  }
}
