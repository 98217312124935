import { Injectable } from "@angular/core";
import {
  FreshdeskPrefillDataType,
  FreshdeskService,
} from "./freshdesk.service";
import { ErrorTraceService } from "./error-trace.service";
import { IqError } from "./error-normalizer.service";
import { CurrentTenantService } from "./current-tenant.service";
import { UaParserService } from "./ua-parser.service";
import { Translation, TranslationsService } from "./translations.service";
import { AuthUserStoreService } from "../_store/auth-user-store.service";
import { AuthUser } from "../_models/auth-user";

@Injectable({
  providedIn: "root",
})
export class SupportService {
  private useFreshDesk: boolean = false;
  constructor(
    private _freshDesk: FreshdeskService,
    private _authUser: AuthUserStoreService,
    private _errorTraceService: ErrorTraceService,
    private _currentTenantService: CurrentTenantService,
    private _uaParserService: UaParserService,
    private _translationsService: TranslationsService
  ) {
    if (this._freshDesk.isAvailable()) {
      this.useFreshDesk = true;
      this._freshDesk.hideLauncher();

      this._authUser.observable.subscribe(async (user: AuthUser | null) => {
        if (!user) {
          return;
        }

        await this._freshDesk.identify(
          user.user.email,
          user.iqUser.userFullName ?? user.user.email
        );
      });
    }
  }

  public async openFor(error: Error | IqError | null): Promise<void> {
    (await this.useFreshDesk)
      ? this.openFreshDesk(error)
      : this.openMail(error);
  }

  public async openFreshDesk(error: Error | IqError | null): Promise<void> {
    const prefillData: FreshdeskPrefillDataType = {
      priority: FreshdeskService.PRIORITY_MEDIUM,
      status: FreshdeskService.STATUS_OPEN,
      group_id: FreshdeskService.GROUP_ID_IQ_SUPPORT,
      product_id: FreshdeskService.PRODUCT_ID_IQ,
      type: "Question",
      description:
        this.buildDebugInfo(error) +
        "\n\n\n" +
        (await this._translationsService.resolvePromise(
          new Translation("services.support.below_this_line")
        )) +
        "\n" +
        "------------------------------------" +
        "\n\n\n" +
        (await this._translationsService.resolvePromise(
          new Translation("services.support.please_describe_issue_here")
        )),
      custom_fields: {
        cf_browser: this.detectBrowser() ?? "Andere",
        cf_link_zum_problem: this.detectUrl(false),
      },
    };

    if (error) {
      if (error instanceof Error) {
        prefillData.subject = this.truncate(
          "JS-Error " + (error.message ?? "")
        );
        prefillData.priority = FreshdeskService.PRIORITY_HIGH;
        prefillData.group_id = FreshdeskService.GROUP_ID_IQ_FE_BUG;
        prefillData.type = "Problem";
      } else {
        const isBeBug =
          error.httpStatus &&
          error.httpStatus >= 500 &&
          error.httpStatus <= 599;

        prefillData.subject = this.truncate(
          "JS-Error " + (error.message ?? "")
        );
        if (isBeBug) {
          prefillData.priority = FreshdeskService.PRIORITY_HIGH;
          prefillData.group_id = FreshdeskService.GROUP_ID_IQ_BE_BUG;
          prefillData.type = "Problem";
        }
      }
    }

    await this._freshDesk.prefill(prefillData);
    await this._freshDesk.hideFields([
      //"subject",
      //'description',
      //"priority",
      "status",
      "group_id",
      "product_id",
      "type",
      "custom_fields.cf_browser",
      "custom_fields.cf_link_zum_problem",
    ]);
    await this._freshDesk.open();
  }

  public async openMail(error: Error | IqError | null): Promise<void> {
    let subject = "Question";
    const body =
      this.buildDebugInfo(error) +
      "\n\n\n" +
      (await this._translationsService.resolvePromise(
        new Translation("services.support.below_this_line")
      )) +
      "\n" +
      "------------------------------------" +
      "\n\n\n" +
      (await this._translationsService.resolvePromise(
        new Translation("services.support.please_describe_issue_here")
      ));

    if (error) {
      if (error instanceof Error) {
        subject = ("JS-Error " + (error.message ?? "")).trim();
      } else {
        subject = (error.id + " " + (error.message ?? "")).trim();
      }
    }

    const href =
      "mailto:iq-support@digital-control.de?subject" +
      encodeURIComponent(subject) +
      "=&body=" +
      encodeURIComponent(body);
    window.open(href, "_blank")?.focus();
  }

  public async open(): Promise<void> {
    (await this.useFreshDesk) ? this.openFreshDesk(null) : this.openMail(null);
  }

  private buildDebugInfo(error: IqError | Error | null): string {
    let debugInfo = "";

    debugInfo += "Url:";
    debugInfo += "\n" + window.location.hostname;
    debugInfo += "\n" + this.detectUrl(true);
    debugInfo += "\n\n";

    debugInfo += "Tenant:";
    debugInfo += "\n" + (this._currentTenantService.currentTenantName ?? "-");
    debugInfo += "\n" + this._currentTenantService.currentTenantId;
    debugInfo += "\n\n";

    if (error) {
      debugInfo += "Error:";
      if (error instanceof Error) {
        debugInfo += "\n" + error.message;
        debugInfo += "\n" + error.stack;
      } else {
        debugInfo += "\n" + error.id;
        if (error.message) {
          debugInfo += "\n" + error.message;
        }
        if (error.description) {
          debugInfo += "\n" + error.description;
        }
      }
      debugInfo += "\n\n";
    }

    const ua = this._uaParserService.parse(window.navigator.userAgent);
    debugInfo += "Browser:";
    if (ua.browserName) {
      debugInfo += "\n" + ua.browserName + " " + ua.browserVersion;
    }
    if (ua.osName) {
      debugInfo += "\n" + ua.osName + " " + ua.osVersion;
    }
    if (ua.deviceName) {
      debugInfo += "\n" + ua.deviceName + " " + ua.deviceVersion;
    }
    debugInfo += "\n" + ua.ua;
    debugInfo += "\n\n";

    debugInfo += "Prev Errors:";
    const prevErrors = this._errorTraceService.getErrors();
    let prevErrorsIndex = 0;
    prevErrors.forEach((error) => {
      prevErrorsIndex++;
      if (error instanceof Error) {
        debugInfo += "\n" + error.message;
        if (prevErrorsIndex <= 3) {
          debugInfo += "\n" + error.stack;
        }
      } else {
        debugInfo += "\n" + error;
      }
    });

    return debugInfo;
  }

  private detectBrowser():
    | "Chrome"
    | "Edge"
    | "Firefox"
    | "Safari"
    | "Opera"
    | null {
    const browser = this._uaParserService.parse(
      window.navigator.userAgent
    ).browserName;
    if (browser === null) {
      return null;
    }

    if (
      browser === "Chrome" ||
      browser === "Edge" ||
      browser === "Firefox" ||
      browser === "Safari" ||
      browser === "Opera"
    ) {
      return browser;
    }

    return null;
  }

  public isAvailable(): boolean {
    return true;
  }

  private truncate(
    str: string,
    maxLength: number = 255,
    appendIfTruncated: string = " ...",
    trim: boolean = true
  ): string {
    if (trim) {
      str = str.trim();
    }

    if (str.length > maxLength) {
      str =
        str.substring(0, maxLength - appendIfTruncated.length) +
        appendIfTruncated;
    }

    return str;
  }

  private detectUrl(addTenantId: boolean = true): string {
    const url = new URL(window.location.href);
    if (!addTenantId || url.searchParams.has("tenant_id")) {
      return url.toString();
    }

    const tenantId = this._currentTenantService.currentTenantId;
    if (tenantId === null) {
      return url.toString();
    }

    url.searchParams.set("tenant_id", tenantId);
    return url.toString();
  }
}
