import { Pipe, PipeTransform } from "@angular/core";
import {
  CombinedTranslation,
  ConstTranslation,
  FallbackTranslation,
  JsTranslation,
  Translation,
  TranslationsService,
  TranslationType,
} from "src/app/_services/translations.service";
import { map } from "rxjs/operators";
import { AsyncPipe } from "@angular/common";

@Pipe({ name: "translatable" })
export class TranslatablePipe implements PipeTransform {
  constructor(
    private _translationsService: TranslationsService,
    private _asyncPipe: AsyncPipe
  ) {}

  transform(value: string | TranslationType, fallbackText?: string): string {
    if (
      value instanceof Translation ||
      value instanceof FallbackTranslation ||
      value instanceof ConstTranslation ||
      value instanceof CombinedTranslation ||
      value instanceof JsTranslation
    ) {
      return (
        this._asyncPipe.transform(
          this._translationsService
            .resolve(
              value,
              undefined,
              undefined,
              typeof fallbackText === "string",
              fallbackText ?? null
            )
            .pipe(map((v) => v ?? ""))
        ) ?? ""
      );
    }

    return value;
  }
}
