import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { map, shareReplay, tap } from "rxjs/operators";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";
import { combineLatest, Observable, Subject } from "rxjs";
import { IqUser } from "src/app/_models/iq-user";
import { CurrentTenantService } from "src/app/_services/current-tenant.service";
import { v4 as uuidv4 } from "uuid";
import { Scope, Scopes } from "src/app/_models/scope";
import { Translation } from "src/app/_services/translations.service";
import { DarkModeRepositoryService } from "src/app/_services/repository/dark-mode-repository.service";
import { DebugModeRepositoryService } from "src/app/_services/repository/debug-mode-repository.service";
import { TenantId } from "src/../../src/types/tenant-id";

// Metadata

export interface SideBarLink {
  path: string;
  title: Translation;
  scopes?: Scope[] | null;
  dev?: boolean;
  subscriptionOwner?: boolean;
}

export interface SideBarMainLink extends SideBarLink {
  uuid: string;
  children?: SideBarSubLink[];
}

export interface SideBarSubLink extends SideBarLink {
  title: Translation;
  icon: string;
}

// Menu Items
export const SIDEBAR_ITEMS: SideBarMainLink[] = [
  {
    uuid: uuidv4(),
    path: "/report",
    title: new Translation("components.sidebar.items.report.title"),
    children: [
      {
        path: "explore",
        title: new Translation("components.sidebar.items.explore.title"),
        icon: "explore",
        scopes: [
          Scopes.REPORTINGS_REPORTING_DATA_SHOW,
          Scopes.REPORTINGS_REPORTING_FIELDS_SHOW,
        ],
      },
      {
        path: "dashboard",
        title: new Translation("components.sidebar.items.dashboard.title"),
        icon: "insights",
        scopes: [
          Scopes.TENANT_DASHBOARDS_SHOW,
          Scopes.REPORTINGS_REPORTING_FIELDS_SHOW,
          Scopes.REPORTINGS_STATISTICS_SHOW,
          Scopes.TENANT_TENANTS_SHOW,
          Scopes.SECURITY_USERS_SHOW,
        ],
        dev: true,
      },
      {
        path: "reports",
        title: new Translation("components.sidebar.items.reports.title"),
        icon: "table_chart",
        scopes: [Scopes.TENANT_REPORTS_SHOW],
      },
    ],
  },
  {
    uuid: uuidv4(),
    path: "/connect-data",
    title: new Translation("components.sidebar.items.connect_data.title"),
    children: [
      {
        path: "dimensions",
        title: new Translation("components.sidebar.items.dimensions.title"),
        icon: "list",
        scopes: [
          Scopes.REPORTINGS_REPORTING_FIELDS_SHOW,
          Scopes.REPORTINGS_CUSTOM_DIMENSIONS_SHOW,
        ],
      },
      {
        path: "metrics",
        title: new Translation("components.sidebar.items.metrics.title"),
        icon: "bar_chart",
        scopes: [
          Scopes.REPORTINGS_REPORTING_FIELDS_SHOW,
          Scopes.REPORTINGS_CUSTOM_METRICS_SHOW,
        ],
      },
      {
        path: "currencies",
        title: new Translation("components.sidebar.items.currencies.title"),
        icon: "payments",
        scopes: [Scopes.TENANT_CURRENCIES_SHOW, Scopes.CURRENCY_OVERVIEW],
        dev: true,
      },
      {
        path: "datasources",
        title: new Translation("components.sidebar.items.datasources.title"),
        icon: "mediation",
        scopes: [Scopes.TENANT_DATASOURCES_SHOW],
      },
      {
        path: "shared-datasources",
        title: new Translation(
          "components.sidebar.items.shared_datasources.title"
        ),
        icon: "hub",
        scopes: [Scopes.TENANT_SHARED_DATASOURCES_SHOW],
      },
      {
        path: "outgoing-datashares",
        title: new Translation(
          "components.sidebar.items.outgoing_datashares.title"
        ),
        icon: "share",
        scopes: [Scopes.REPORTINGS_OUTGOING_DATASHARE_SHOW],
      },
      {
        path: "datasource-types",
        title: new Translation(
          "components.sidebar.items.datasource_types.title"
        ),
        icon: "mediation",
        scopes: [Scopes.TENANT_DATASOURCE_TYPES_SHOW],
        dev: true,
      },
      {
        path: "credentials",
        title: new Translation("components.sidebar.items.credentials.title"),
        icon: "lock",
        scopes: [Scopes.TENANT_CREDENTIALS_SHOW],
        dev: true,
      },
    ],
  },
  {
    uuid: uuidv4(),
    path: "/admin",
    title: new Translation("components.sidebar.items.admin.title"),
    children: [
      {
        path: "tenants",
        title: new Translation("components.sidebar.items.tenants.title"),
        icon: "workspaces",
        scopes: [],
      },
      {
        path: "users",
        title: new Translation("components.sidebar.items.users.title"),
        icon: "person",
        scopes: [Scopes.SECURITY_GROUPS_SHOW, Scopes.SECURITY_USERS_SHOW],
      },
      {
        path: "subscriptions",
        title: new Translation("components.sidebar.items.subscriptions.title"),
        icon: "settings",
        scopes: [Scopes.ALL],
        subscriptionOwner: true,
      },
      {
        path: "errors",
        title: new Translation("components.sidebar.items.errors.title"),
        icon: "bug_report",
        dev: true,
      },
      {
        path: "debug-dashboard",
        title: new Translation(
          "components.sidebar.items.debug_dashboard.title"
        ),
        icon: "bug_report",
        dev: true,
      },
    ],
  },
];

@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnDestroy {
  public menuItems: Observable<SideBarMainLink[] | undefined>;
  public openMenuItems: string[] = [];
  public user: Observable<IqUser | null>;
  public userIsAdmin: Observable<boolean>;
  public userLanguage: Observable<string | null>;
  public currentTenant: Observable<{
    id: TenantId;
    name: string;
    defaultCurrency: string | null;
  } | null>;
  private $destroyed = new Subject<void>();
  public $darkMode: Observable<boolean | null>;
  @Output()
  public menuChanged = new EventEmitter<void>();

  constructor(
    private _currentTenantService: CurrentTenantService,
    private _authUserStore: AuthUserReadonlyRepositoryService,
    private _darkModeStoreService: DarkModeRepositoryService,
    _debugModeStoreService: DebugModeRepositoryService
  ) {
    this.openMenuItems = SIDEBAR_ITEMS.map((item) => item.uuid);
    this.user = this._authUserStore.observable;
    this.userIsAdmin = this.user.pipe(
      map((user) => !!(user && user.isAdmin()))
    );
    this.userLanguage = this.user.pipe(map((user) => user && user.language));
    this.$darkMode = this._darkModeStoreService.observable;
    this.currentTenant = this._currentTenantService.observable;

    this.menuItems = combineLatest([
      this.currentTenant,
      this.user,
      _debugModeStoreService.observable,
    ])
      .pipe(
        map(([tenant, user, debugMode]) => {
          if (!user) {
            return;
          }

          return SidebarComponent.filter(
            SIDEBAR_ITEMS,
            tenant?.id ?? null,
            user,
            debugMode
          );
        })
      )
      .pipe(
        tap(() => {
          this.menuChanged.emit();
        })
      )
      .pipe(shareReplay(1));
  }

  private static filter(
    items: SideBarMainLink[],
    tenantId: TenantId | null,
    user: IqUser,
    debugMode: boolean
  ): SideBarMainLink[] {
    return items
      .map((menuItem: SideBarMainLink): SideBarMainLink | null => {
        if (
          !SidebarComponent.sidebarLinkVisibleForUser(
            menuItem,
            tenantId,
            user,
            debugMode
          )
        ) {
          return null;
        }

        const children = menuItem.children || [];
        const filteredChildren = children.filter((childItem) =>
          SidebarComponent.sidebarLinkVisibleForUser(
            childItem,
            tenantId,
            user,
            debugMode
          )
        );

        // hide menu item if children exist but now none are visible
        if (children.length > 0 && filteredChildren.length === 0) {
          return null;
        }

        menuItem = Object.assign({}, menuItem);
        menuItem.children = filteredChildren;
        return menuItem;
      })
      .filter((i: SideBarMainLink | null) => i !== null)
      .map((i: SideBarMainLink | null) => i as SideBarMainLink);
  }

  private static sidebarLinkVisibleForUser(
    item: SideBarLink,
    tenantId: TenantId | null,
    user: IqUser,
    showDevItems: boolean
  ): boolean {
    if (item.dev && !showDevItems) {
      return false;
    }

    if (
      item.subscriptionOwner &&
      !(user && (user.isAdmin() || user.isSubscriptionOwnerOfAny()))
    ) {
      return false;
    }

    const scopes = Scope.createMany(item.scopes || []);
    return (
      scopes.length === 0 ||
      (user && user.userCanAllForTenant(scopes, tenantId))
    );
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public toggleMainItem(menuItem: SideBarMainLink): void {
    if (this.openMenuItems.some((uuid) => uuid === menuItem.uuid)) {
      this.openMenuItems = this.openMenuItems.filter(
        (uuid) => uuid !== menuItem.uuid
      );
    } else {
      this.openMenuItems.push(menuItem.uuid);
    }

    this.menuChanged.emit();
  }

  public isMainItemOpened(menuItem: SideBarMainLink): boolean {
    return this.openMenuItems.some((uuid) => uuid === menuItem.uuid);
  }
}
