import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { AuthService } from "src/app/_services/auth.service";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";
import { Scope } from "src/app/_models/scope";
import { isTenantId, validTenantId } from "src/../../src/types/tenant-id";
import { CurrentTenantService } from "../_services/current-tenant.service";
import { StringScope } from "../../../../src/types/string-scope";

export interface RouteAuthGuardData {
  guards: {
    scopes: (StringScope | Scope)[];
  };
}

function instanceOfRouteAuthGuardData(
  object: any
): object is RouteAuthGuardData {
  return (
    "guards" in object &&
    typeof object.guards === "object" &&
    object.guards &&
    "scopes" in object.guards &&
    Array.isArray(object.guards.scopes)
  );
}

@Injectable({ providedIn: "root" })
export class AuthGuardService {
  constructor(
    private _authService: AuthService,
    private _currentTenantService: CurrentTenantService,
    private _authUserStore: AuthUserReadonlyRepositoryService,
    private _router: Router
  ) {}

  private _canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const guardData: RouteAuthGuardData | null = instanceOfRouteAuthGuardData(
      route.data
    )
      ? route.data
      : null;
    const scopes = guardData ? Scope.createMany(guardData.guards.scopes) : [];
    const tenantId = route.queryParamMap.get("tenant_id");
    const currentTenantId = tenantId
      ? validTenantId(tenantId)
      : this._currentTenantService.currentTenantId;
    return (
      isTenantId(tenantId)
        ? this._authService.initLoginAndSwitchTenant(tenantId)
        : this._authService.initLogin()
    ).pipe(
      map((user) => {
        if (!user) {
          return false;
        }

        return (
          !!user && user.iqUser.userCanAllForTenant(scopes, currentTenantId)
        );
      }),
      tap((authorized) => {
        console.debug(
          "AuthGuardService",
          scopes.map((s) => s.toString()),
          authorized ? "access granted" : "access denied"
        );
      }),
      map((authorized) => {
        if (!authorized) {
          if (!this._authUserStore.isCurrentlyLoggedIn) {
            let returnUrl: string | null = state.url;
            returnUrl =
              returnUrl === null || returnUrl === "/" || returnUrl.trim() === ""
                ? null
                : returnUrl;
            return this._router.createUrlTree(
              ["/auth/login"],
              returnUrl !== null
                ? {
                    queryParams: { returnUrl: returnUrl },
                  }
                : {}
            );
          } else {
            return this._router.createUrlTree([""]);
          }
        }
        return authorized;
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this._canActivate(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this._canActivate(childRoute, state);
  }

  fullUrl(route: ActivatedRouteSnapshot): string {
    const str = route.url.map((u) => u.path).join("/");
    if (route.parent) {
      return this.fullUrl(route.parent) + "/" + str;
    }
    return str;
  }
}
