import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SidebarComponent } from "./sidebar.component";
import { MaterialModule } from "src/app/_modules/material.module";
import { SharedModule } from "src/app/_modules/shared.module";

@NgModule({
  imports: [RouterModule, CommonModule, MaterialModule, SharedModule],
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
})
export class SidebarModule {}
