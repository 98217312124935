import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AuthGuardService } from "src/app/_guards/auth-guard.service";
import { LoginComponent } from "./pages/login/login.component";
import { PasswordResetComponent } from "./pages/password-reset/password-reset.component";
import { PasswordResetPasswordComponent } from "./pages/password-reset-password/password-reset-password.component";
import { GoogleDatastudioComponent } from "./connectors/google-datastudio/google-datastudio.component";
import { TenantGuardService } from "src/app/_guards/tenant-guard.service";
import { OauthComponent } from "./pages/oauth/oauth.component";
import { AuthResolverService } from "src/app/_resolvers/auth-resolver.service";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { IndexGuardService } from "src/app/_guards/index-guard.service";
import { BackwardsCompatibilityGuardService } from "src/app/_guards/backwards-compatibility-redirect-guard.service";
import { NoAuthGuardService } from "src/app/_guards/no-auth-guard.service";

export const AppRoutes: Routes = [
  {
    path: "",
    component: PageNotFoundComponent, // redirect is dynamic and handled in canActivate
    canActivate: [AuthGuardService, IndexGuardService],
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: "report",
        canActivate: [TenantGuardService],
        loadChildren: () =>
          import("./report/report.module").then((m) => m.ReportModule),
      },
      {
        path: "connect-data",
        canActivate: [TenantGuardService],
        loadChildren: () =>
          import("./connect-data/connect-data.module").then(
            (m) => m.ConnectDataModule
          ),
      },
      {
        path: "user",
        canActivate: [],
        loadChildren: () =>
          import("./user/user.module").then((m) => m.UserModule),
      },
      {
        path: "admin",
        canActivate: [],
        loadChildren: () =>
          import("./admin/admin.module").then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
        canActivate: [NoAuthGuardService],
      },
      {
        path: "password-reset",
        component: PasswordResetComponent,
      },
      {
        path: "new-password",
        component: PasswordResetPasswordComponent,
      },
    ],
  },
  {
    path: "google_auth_connector",
    canActivate: [AuthGuardService],
    component: GoogleDatastudioComponent,
  },
  {
    path: "oauth",
    canActivate: [AuthGuardService],
    component: OauthComponent,
    resolve: {
      oAuth: AuthResolverService,
    },
  },
  // Wild Card Route for 404 request
  {
    path: "**",
    canActivate: [BackwardsCompatibilityGuardService],
    pathMatch: "full",
    component: PageNotFoundComponent,
  },
];
