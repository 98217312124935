import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { NavbarComponent } from "src/app/shared/navbar/navbar.component";
import { TenantEventsService } from "src/app/_services/tenant-events.service";
import { filter, takeUntil } from "rxjs/operators";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { ToastService } from "src/app/_services/toast.service";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";
import { IqUser } from "src/app/_models/iq-user";
import { UserLanguageStoreService } from "src/app/_store/readonly/user-language.store.service";
import { SidebarComponent } from "../../sidebar/sidebar.component";
import { Tenant } from "src/app/_services/api/tenant.service";
import { AuthService } from "src/app/_services/auth.service";
import { SecurityService } from "src/app/_services/api/security.service";
import { ErrorHandlerService } from "src/app/_services/error-handler.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  private _$destroyed = new Subject<void>();
  public $user: Observable<IqUser | null>;
  public $userLanguage: Observable<string | null>;

  @ViewChild(SidebarComponent) sidebar: SidebarComponent;
  @ViewChild(NavbarComponent) navbar: NavbarComponent;
  private _elemMainPanel: HTMLElement | null = null;
  private _elemSidebar: HTMLElement | null = null;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _tenantEventsService: TenantEventsService,
    private _authUserStoreService: AuthUserReadonlyRepositoryService,
    private _userLanguageStoreService: UserLanguageStoreService,
    private _securityService: SecurityService,
    private _errorHandlerService: ErrorHandlerService,
    private _toastService: ToastService
  ) {
    this.$user = this._authUserStoreService.observable;
    this.$userLanguage = this._userLanguageStoreService.observable;
  }

  ngOnInit() {
    this._tenantEventsService
      .eventsFor("SystemNotification")
      .pipe(takeUntil(this._$destroyed))
      .subscribe((event) => {
        this._toastService.show({
          disableTimeOut: true,
          custom: {
            type: event.payload.type ?? "info",
            message: event.payload.message,
          },
        });
      });

    this._elemSidebar = <HTMLElement>(
      document.querySelector(".iq-admin-layout .iq-sidebar .sidebar-wrapper")
    );
    this._elemMainPanel = <HTMLElement>(
      document.querySelector(".iq-admin-layout .iq-main-panel")
    );

    this._router.events
      .pipe(takeUntil(this._$destroyed))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this._elemMainPanel) {
          this._elemMainPanel.scrollTop = 0;
        }
        if (this._elemSidebar) {
          this._elemSidebar.scrollTop = 0;
        }
        this.closeMobileSidebar();
      });
  }

  ngOnDestroy() {
    this._$destroyed.next();
    this._$destroyed.complete();
  }

  public logout(): void {
    this._authService.logout();
  }

  public switchToTenant(tenant: Tenant) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("tenant_id", tenant.id);
    window.location.search = searchParams.toString();
  }

  public changeLanguage(language) {
    if (!language) {
      return;
    }

    firstValueFrom(this._securityService.updateMe({ language: language }))
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        this._errorHandlerService.handle(e);
      });
  }

  public closeMobileSidebar(): void {
    const elSidebar = document.getElementsByClassName("iq-sidebar")[0] as
      | HTMLElement
      | undefined;
    if (!elSidebar) {
      return;
    }
    elSidebar.classList.remove("open");
  }

  public toggleMobileSidebar(): void {
    const elSidebar = document.getElementsByClassName("iq-sidebar")[0] as
      | HTMLElement
      | undefined;
    if (!elSidebar) {
      return;
    }
    elSidebar.classList.toggle("open");
  }
}
