import { assertDefined } from './defined';
import { assertNotNull } from './not-null';

export function isDefinedNotNull<T>(value: T | undefined | null): value is T {
  return typeof value !== 'undefined' && value !== null;
}

export function assertDefinedNotNull<T>(value: T | undefined | null): asserts value is T {
  if (!isDefinedNotNull<T>(value)) {
    assertDefined<T | null>(value);
    assertNotNull<T | undefined>(value);
  }
}

export function validDefinedNotNull<T>(value: T | undefined | null): T {
  assertDefinedNotNull<T>(value);
  return value;
}
