import { Injectable } from "@angular/core";

export type FreshdeskStatusType =
  | typeof FreshdeskService.STATUS_OPEN
  | typeof FreshdeskService.STATUS_PENDING
  | typeof FreshdeskService.STATUS_RESOLVED
  | typeof FreshdeskService.STATUS_CLOSED;
export type FreshdeskPriorityType =
  | typeof FreshdeskService.PRIORITY_LOW
  | typeof FreshdeskService.PRIORITY_MEDIUM
  | typeof FreshdeskService.PRIORITY_HIGH
  | typeof FreshdeskService.PRIORITY_URGENT;

interface FreshdeskPrefillDefaultDataType {
  subject?: string;
  description?: string;
  priority?: FreshdeskPriorityType;
  status?: FreshdeskStatusType;
  group_id?: number;
  product_id?: number;
  type?: "Question" | "Incident" | "Problem" | "Feature Request" | "Refund"; // TODO keep up to date
}
interface FreshdeskPrefillCustomDataType {
  cf_browser?: "Chrome" | "Edge" | "Firefox" | "Safari" | "Opera" | "Andere"; // TODO keep up to date
  cf_link_zum_problem?: string;
}
export interface FreshdeskPrefillDataType
  extends FreshdeskPrefillDefaultDataType {
  custom_fields?: FreshdeskPrefillCustomDataType;
}
type FreshdeskDefaultFieldType = keyof FreshdeskPrefillDefaultDataType;
type FreshdeskCustomFieldType =
  | "custom_fields.cf_browser"
  | "custom_fields.cf_link_zum_problem";
export type FreshdeskFieldType =
  | FreshdeskDefaultFieldType
  | FreshdeskCustomFieldType;

@Injectable({
  providedIn: "root",
})
export class FreshdeskService {
  public static readonly STATUS_OPEN = 2;
  public static readonly STATUS_PENDING = 3;
  public static readonly STATUS_RESOLVED = 4;
  public static readonly STATUS_CLOSED = 5;
  public static readonly PRIORITY_LOW = 1;
  public static readonly PRIORITY_MEDIUM = 2;
  public static readonly PRIORITY_HIGH = 3;
  public static readonly PRIORITY_URGENT = 4;
  public static readonly GROUP_ID_IQ_FE_BUG = 101000297437;
  public static readonly GROUP_ID_IQ_BE_BUG = 101000297437;
  public static readonly GROUP_ID_IQ_SUPPORT = 101000297437;
  public static readonly PRODUCT_ID_IQ = 101000002344;

  constructor() {}

  public isAvailable(): boolean {
    return typeof window["FreshworksWidget"] === "function";
  }

  private _call(p1: string, p2?: string, p3?: any): Promise<void> {
    const widget = window["FreshworksWidget"];
    if (typeof widget !== "function") {
      return Promise.reject("FreshworksWidget not initialized");
    }

    widget(p1, p2, p3);

    return Promise.resolve();
  }

  public async hide(): Promise<void> {
    await this._call("hide");
  }

  public async hideLauncher(): Promise<void> {
    await this._call("hide", "launcher");
  }

  public async show(): Promise<void> {
    await this._call("show");
  }

  public async showLauncher(): Promise<void> {
    await this._call("show", "launcher");
  }

  public async open(): Promise<void> {
    await this._call("open");
  }

  public async openForm(): Promise<void> {
    await this._call("open", "ticketForm");
  }

  public async close(): Promise<void> {
    await this._call("close");
  }

  public async destroy(): Promise<void> {
    await this._call("destroy");
  }

  public async boot(): Promise<void> {
    await this._call("boot");
  }

  public async identify(email: string, name: string): Promise<void> {
    await this._call("identify", "ticketForm", {
      name: name,
      email: email,
    });
  }

  public async prefill(data: FreshdeskPrefillDataType): Promise<void> {
    await this._call("prefill", "ticketForm", data);
  }

  public async disableFields(fields: FreshdeskFieldType[]): Promise<void> {
    await this._call("disable", "ticketForm", fields);
  }

  public async hideFields(fields: FreshdeskFieldType[]): Promise<void> {
    await this._call("hide", "ticketForm", fields);
  }

  public async clearForm(): Promise<void> {
    await this._call("clear", "ticketForm");
  }
}
