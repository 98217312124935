export type TypeIf<Tbool extends boolean, Ttrue, Tfalse> =
  Tbool extends true ? Ttrue
  : Tbool extends false ? Tfalse
  : Ttrue | Tfalse;

export type KeyOfObjectWhereType<Object, WhereValue> = keyof {
  [P in keyof Object as Object[P] extends WhereValue ? P : never]: any;
};

export type Partial<Object> = {
  [P in keyof Object]?: Object[P] | undefined;
};

export function concat<A extends string, B extends string>(a: A, b: B): `${A}${B}` {
  return (a + b) as `${A}${B}`;
}

export type Mutable<T> = { -readonly [P in keyof T]: T[P] };
export type Readonly<T> = { +readonly [P in keyof T]: T[P] };

export function objectKeys<T extends {}>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export function objectMap<
  Keys extends string,
  A extends {
    [key in Keys]: unknown;
  },
  B extends {
    [key in Keys]: unknown;
  }
>(obj: A, mapFunction: <K extends Keys>(key: K, valueA: A[K]) => B[K]): B {
  const newObj = {} as B;
  (objectKeys(obj) as Keys[]).forEach((key) => {
    newObj[key] = mapFunction(key, obj[key]);
  });
  return newObj;
}
