import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { HostsService } from "./hosts.service";
import { CurrentTenantService } from "./current-tenant.service";
import { WebSocketWithRetry } from "src/app/shared/websocket-with-retry";
import { filter, map, switchMap } from "rxjs/operators";
import { Scopes } from "src/app/_models/scope";
import { ToastMessageType } from "./toast.service";
import { AuthUserReadonlyRepositoryService } from "./repository/auth-user-readonly-repository.service";
import { TenantId } from "src/../../src/types/tenant-id";

type PayloadByEventType = {
  DatasourceTemplateFetchStarted: {
    datasourceTemplateId: string;
    dates: null | string[];
  };
  DatasourceTemplateFetchProgress: {
    datasourceTemplateId: string;
    dates: null | string[];
    progress: number;
  };
  DatasourceTemplateFetchFailed: {
    datasourceTemplateId: string;
    dates: null | string[];
  };
  DatasourceTemplateFetchDone: {
    datasourceTemplateId: string;
    dates: null | string[];
  };
  DatasourceTemplateDeleteStarted: {
    datasourceTemplateId: string;
    dates: null | string[];
  };
  DatasourceTemplateDeleteDone: {
    datasourceTemplateId: string;
    dates: null | string[];
  };
  DatasourceTemplateImportStarted: {
    datasourceTemplateId: string;
    dates: null | string[];
  };
  DatasourceTemplateImportFailed: {
    datasourceTemplateId: string;
    dates: null | string[];
  };
  DatasourceTemplateImportDone: {
    datasourceTemplateId: string;
    dates: null | string[];
  };
  TenantFieldsNewAvailable: {};
  TenantFieldsUpdated: {};
  TenantDatasourceNewAvailable: {};
  TenantDatasourceUpdated: {};
  UserChanged: { userId: string };
  SystemNotification: { message: string; type?: ToastMessageType };
};

type EventType = keyof PayloadByEventType;

export type TenantEventDatasourceTemplate = TenantEvent<
  | "DatasourceTemplateFetchStarted"
  | "DatasourceTemplateFetchProgress"
  | "DatasourceTemplateFetchFailed"
  | "DatasourceTemplateFetchDone"
  | "DatasourceTemplateDeleteStarted"
  | "DatasourceTemplateDeleteDone"
  | "DatasourceTemplateImportStarted"
  | "DatasourceTemplateImportFailed"
  | "DatasourceTemplateImportDone"
>;

export interface TenantEvent<T extends EventType> {
  tenantId: TenantId;
  type: T;
  payload: PayloadByEventType[T];
}

@Injectable({
  providedIn: "root",
})
export class TenantEventsService {
  private _events: Observable<TenantEvent<EventType>>;

  constructor(
    private _currentTenantService: CurrentTenantService,
    private _authUserRepositoryService: AuthUserReadonlyRepositoryService,
    private _hostsService: HostsService
  ) {
    this._events = this._createWs();
  }

  private getWsUrl(): Observable<string | null> {
    return this._authUserRepositoryService.observableAuthUser.pipe(
      map((user): string | null => {
        if (!user || !user.iqUser.userCanForAnyTenant(Scopes.EVENTS_LISTEN)) {
          return null;
        }

        return `${
          this._hostsService.eventsHost
        }/tenants/my?token=${encodeURIComponent(user.accessToken.toString())}`;
      })
    );
  }

  public events(): Observable<TenantEvent<EventType>> {
    return this._currentTenantService.observable.pipe(
      switchMap((tenant) => {
        return !tenant ? EMPTY : this.eventsByTenantId(tenant.id);
      })
    );
  }

  public eventsFor<T extends EventType>(
    type: T | T[]
  ): Observable<TenantEvent<T>> {
    return this._currentTenantService.observable
      .pipe(
        switchMap((tenant) => {
          return !tenant ? EMPTY : this.eventsByTenantId(tenant.id);
        })
      )
      .pipe(
        filter((event: TenantEvent<EventType>): event is TenantEvent<T> =>
          Array.isArray(type)
            ? (type as EventType[]).includes(event.type)
            : event.type === type
        )
      );
  }

  public eventsForDatasourceTemplate(): Observable<TenantEventDatasourceTemplate> {
    return this.eventsFor([
      "DatasourceTemplateFetchStarted",
      "DatasourceTemplateFetchProgress",
      "DatasourceTemplateFetchFailed",
      "DatasourceTemplateFetchDone",
      "DatasourceTemplateDeleteStarted",
      "DatasourceTemplateDeleteDone",
      "DatasourceTemplateImportStarted",
      "DatasourceTemplateImportFailed",
      "DatasourceTemplateImportDone",
    ]);
  }

  public eventsByTenantId(
    tenantId: TenantId
  ): Observable<TenantEvent<EventType>> {
    return this._events.pipe(filter((event) => event.tenantId === tenantId));
  }

  private _createWs(): Observable<TenantEvent<EventType>> {
    return new WebSocketWithRetry(this.getWsUrl()).messages.pipe(
      map((message: string) => {
        const data: TenantEvent<EventType> = JSON.parse(message);
        if (
          typeof data !== "object" ||
          typeof data.type !== "string" ||
          typeof data.payload !== "object"
        ) {
          return null;
        } else {
          // @ts-ignore
          data.type = data.type.replace(
            /^DataSourceTemplate/,
            "DatasourceTemplate"
          );
          return data;
        }
      }),
      filter(
        (data: TenantEvent<EventType> | null): data is TenantEvent<EventType> =>
          data !== null
      )
    );
  }
}
