import { Injectable } from "@angular/core";
import { HttpRequest } from "./http/http.request.model";

@Injectable({
  providedIn: "root",
})
export class RequestTraceService {
  private requests: string[] = [];
  private readonly MAX_LOGS = 20;

  public add(request: HttpRequest): void {
    this.addId(request.method + " " + request.url);
  }

  public addId(request: string): void {
    this.requests.push(request);
    if (this.requests.length > this.MAX_LOGS) {
      this.requests.shift();
    }
  }

  public getRequests(): string[] {
    return this.requests.reverse();
  }
}
