import { Inject, Injectable, Injector } from "@angular/core";
import { Translatable } from "./translations.service";
import { ActiveToast, IndividualConfig, ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { ToastMessageType as _ToastMessageType } from "../../../../src/types/toast-message-type";

export type ToastMessageType = _ToastMessageType;

export type ToastButtonType =
  | { action: string; label: Translatable }
  | { action: string; icon: string };

export interface CustomToastConfig extends IndividualConfig {
  custom: {
    type: ToastMessageType;
    message: Translatable;
    title?: Translatable;
    buttons?: ToastButtonType[];
    iqErrorId?: string;
  };
}
export interface PartialCustomToastConfig extends Partial<IndividualConfig> {
  custom: CustomToastConfig["custom"];
}

export interface MyActiveToast extends ActiveToast<CustomToastConfig> {
  onAction: Observable<string>;
}

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(@Inject(Injector) private readonly _injector: Injector) {}

  private get _toastrService(): ToastrService {
    return this._injector.get(ToastrService);
  }

  public show(data: PartialCustomToastConfig): MyActiveToast {
    return this._toastrService.show(
      "translatable",
      "translatable",
      data,
      data.custom.type
    );
  }

  public info(
    message: Translatable,
    title?: Translatable,
    buttons?: ToastButtonType[],
    config?: Partial<IndividualConfig>
  ): ActiveToast<CustomToastConfig> {
    const combined: PartialCustomToastConfig = {
      custom: {
        type: "info",
        message: message,
        title: title,
        buttons: buttons,
      },
    };
    if (config) {
      Object.keys(config).forEach((key) => {
        if (key === "custom") {
          return;
        }
        combined[key] = config[key];
      });
    }
    return this.show(combined);
  }

  public success(
    message: Translatable,
    title?: Translatable,
    buttons?: ToastButtonType[],
    config?: Partial<IndividualConfig>
  ): ActiveToast<CustomToastConfig> {
    const combined: PartialCustomToastConfig = {
      custom: {
        type: "success",
        message: message,
        title: title,
        buttons: buttons,
      },
    };
    if (config) {
      Object.keys(config).forEach((key) => {
        if (key === "custom") {
          return;
        }
        combined[key] = config[key];
      });
    }
    return this.show(combined);
  }

  public warning(
    message: Translatable,
    title?: Translatable,
    buttons?: ToastButtonType[],
    config?: Partial<IndividualConfig>
  ): ActiveToast<CustomToastConfig> {
    const combined: PartialCustomToastConfig = {
      custom: {
        type: "warning",
        message: message,
        title: title,
        buttons: buttons,
      },
    };
    if (config) {
      Object.keys(config).forEach((key) => {
        if (key === "custom") {
          return;
        }
        combined[key] = config[key];
      });
    }
    return this.show(combined);
  }

  public danger(
    message: Translatable,
    title?: Translatable,
    buttons?: ToastButtonType[],
    config?: Partial<IndividualConfig>
  ): ActiveToast<CustomToastConfig> {
    const combined: PartialCustomToastConfig = {
      custom: {
        type: "danger",
        message: message,
        title: title,
        buttons: buttons,
      },
    };
    if (config) {
      Object.keys(config).forEach((key) => {
        if (key === "custom") {
          return;
        }
        combined[key] = config[key];
      });
    }
    return this.show(combined);
  }
}
