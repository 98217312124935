import { Injectable } from "@angular/core";

import { UAParser } from "ua-parser-js";

export class ParsedUserAgent {
  public readonly ua: string | null;
  public readonly browserName: string | null = null;
  public readonly browserVersion: string | null = null;
  public readonly engineName: string | null = null;
  public readonly engineVersion: string | null = null;
  public readonly osName: string | null = null;
  public readonly osVersion: string | null = null;
  public readonly deviceName: string | null = null;
  public readonly deviceVersion: string | null = null;
  public readonly cpuArchitecture: string | null = null;

  constructor(userAgent: string | null) {
    if (userAgent === null) {
      this.ua = null;
    } else {
      this.ua = userAgent;
      const parsed = new UAParser(userAgent).getResult();
      this.browserName = parsed?.browser?.name || null;
      this.browserVersion = parsed?.browser?.version || null;
      this.engineName = parsed?.engine?.name || null;
      this.engineVersion = parsed?.engine?.version || null;
      this.osName = parsed?.os?.name || null;
      this.osVersion = parsed?.os?.version || null;
      this.deviceName = parsed?.device?.name || null;
      this.deviceVersion = parsed?.device?.version || null;
      this.cpuArchitecture = parsed?.cpu?.architecture || null;
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class UaParserService {
  public parse(userAgent: string | null): ParsedUserAgent {
    return new ParsedUserAgent(userAgent);
  }
}
