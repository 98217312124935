import {
  Directive,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";
import { Subject } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";

@Directive({
  selector: "[appIsAdmin]",
})
export class IsAdminDirective implements OnInit, OnDestroy {
  private _onDestroy = new Subject<void>();

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authUserStore: AuthUserReadonlyRepositoryService
  ) {}

  ngOnInit() {
    this._authUserStore.observable
      .pipe(takeUntil(this._onDestroy))
      .pipe(map((user) => user && user.isAdmin()))
      .pipe(distinctUntilChanged())
      .subscribe((authorized) => {
        if (authorized) {
          this._viewContainer.createEmbeddedView(this._templateRef);
        } else {
          this._viewContainer.clear();
        }
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
