import { AccessToken } from "./access-token";
import { IqUser } from "./iq-user";
import { UserWithSubscriptions } from "../_services/api/security.service";

export class AuthUser {
  constructor(
    public readonly accessToken: AccessToken,
    public readonly user: UserWithSubscriptions,
    public readonly iqUser: IqUser
  ) {}
}
