import { HttpParams } from "@angular/common/http";

export type HttpRequestMethod = "GET" | "POST" | "PUT" | "DELETE";

export class HttpRequest {
  public constructor(
    public readonly method: HttpRequestMethod,
    public readonly url: string,
    public readonly body: any,
    public readonly query: HttpParams | null,
    private _headers: { [key: string]: string } | null
  ) {
    if (this.method === "GET" && this.body) {
      throw new Error("GET request must not contain body data.");
    }
  }

  get headers(): { [key: string]: string } | null {
    return this._headers ? Object.assign({}, this._headers) : null;
  }
}
