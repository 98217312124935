import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";
import { IqUser } from "src/app/_models/iq-user";
import { AuthUser } from "../../_models/auth-user";
import { AuthUserStoreService } from "../../_store/auth-user-store.service";
import { CurrentTenantService } from "../current-tenant.service";

@Injectable({
  providedIn: "root",
})
export class AuthUserReadonlyRepositoryService {
  protected _observableIqUser: Observable<IqUser | null>;

  constructor(
    protected _authUserStoreService: AuthUserStoreService,
    protected _currentTenantService: CurrentTenantService
  ) {
    this._observableIqUser = this._authUserStoreService.observable
      .pipe(map((u) => u?.iqUser ?? null))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));
  }

  public get observable(): Observable<IqUser | null> {
    return this._observableIqUser;
  }

  public get observableAuthUser(): Observable<AuthUser | null> {
    return this._authUserStoreService.observable;
  }

  public get $observableAdmin(): Observable<boolean> {
    return this._observableIqUser.pipe(map((u) => !!u && u.isAdmin()));
  }

  public get current(): IqUser | null {
    return this._authUserStoreService.current?.iqUser ?? null;
  }

  public get currentAuthUser(): AuthUser | null {
    return this._authUserStoreService.current;
  }

  public get currentIsAdmin(): boolean {
    const user = this.current;
    return user !== null && user.isAdmin();
  }

  public get isCurrentlyLoggedIn(): boolean {
    return this.current !== null;
  }
}
