import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";

@Component({
  selector: "app-button-list",
  templateUrl: "./button-list.component.html",
  styleUrls: ["./button-list.component.scss"],
})
export class ButtonListComponent implements OnInit, OnChanges {
  @Input() public actionsPosition: "left" | "right" | "spread" | "center" =
    "left";
  @Input() public actionsInverseOrder: boolean = false;
  @Input() public vMargin: boolean = true;
  @Input() public hMargin: boolean = false;
  @HostBinding("class") _class = "";

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    let _class = "actions-" + this.actionsPosition;
    if (this.vMargin) {
      _class += " v-margin";
    }
    if (this.hMargin) {
      _class += " h-margin";
    }
    if (this.actionsInverseOrder) {
      _class += " actions-inverse-order";
    }
    this._class = _class;
  }
}
