import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorHandlerService } from "src/app/_services/error-handler.service";
import {
  Tenant,
  TenantService,
  TenantWithDatasourcesCount,
} from "src/app/_services/api/tenant.service";
import {
  OauthClient,
  SecurityService,
} from "src/app/_services/api/security.service";
import { Scope, Scopes } from "src/app/_models/scope";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-google-datastudio",
  templateUrl: "./google-datastudio.component.html",
  styleUrls: ["./google-datastudio.component.scss"],
})
export class GoogleDatastudioComponent implements OnInit {
  private clientId: string | null = null;
  private redirectUri: string | null = null;
  private state: string | undefined = undefined;
  public scopes: Scope[] = [];
  public tenants: TenantWithDatasourcesCount[] = [];
  public loading: boolean = true;
  public oAuthClient: OauthClient | null = null;
  public requiresTenantIndependent: boolean = false;
  public selectedTenant: TenantWithDatasourcesCount | null = null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _errorHandlerService: ErrorHandlerService,
    private _router: Router,
    private _securityService: SecurityService,
    private _tenantService: TenantService
  ) {
    this._activatedRoute.queryParams.subscribe((routeParams) => {
      if (Object.keys(routeParams).length === 0) {
        this._router.navigate([""]);
      }

      this.clientId =
        typeof routeParams["client_id"] === "string"
          ? routeParams["client_id"]
          : null;
      this.redirectUri =
        typeof routeParams["redirect_uri"] === "string"
          ? routeParams["redirect_uri"]
          : null;
      this.state =
        typeof routeParams["state"] === "string"
          ? routeParams["state"]
          : undefined;
      this.scopes = routeParams["scope"]
        .split(" ")
        .map((scope: string) => Scope.fromString(scope));
      const isLookerStudio =
        this.clientId === "fc5878bc-36da-420b-b7f2-f2d75f98121a";
      this.requiresTenantIndependent =
        this.scopes.some((scope) => scope.equals(Scopes.TENANT_INDEPENDENT)) ||
        isLookerStudio;
    });
  }

  ngOnInit() {
    this.getClientIdInfo()
      .then((oAuthClient) => {
        this.oAuthClient = oAuthClient;
      })
      .then(() => {
        if (!this.requiresTenantIndependent) {
          return this.getUserTenants().then((tenants) => {
            this.tenants = tenants;
          });
        }

        return Promise.resolve();
      })
      .catch((e) => this._errorHandlerService.handle(e))
      .then(() => {
        this.loading = false;
      });
  }

  async getClientIdInfo(): Promise<OauthClient> {
    if (!this.clientId) {
      return Promise.reject("client_id missing");
    }

    return firstValueFrom(
      this._securityService.getOauthClientById(this.clientId)
    ).then((data) => {
      return data.data;
    });
  }

  async getUserTenants(): Promise<TenantWithDatasourcesCount[]> {
    return firstValueFrom(this._tenantService.getMyTenants(false));
  }

  private postAuth(tenant: Tenant | null) {
    const clientId = this.clientId;
    if (!clientId) {
      return;
    }
    const redirectUri = this.redirectUri;
    if (!redirectUri) {
      return;
    }

    firstValueFrom(
      this._securityService.getAuthCodeRedirectUriForClient(
        tenant?.id ?? null,
        clientId,
        this.scopes,
        redirectUri,
        this.state
      )
    )
      .then((redirectUriWithParams) => {
        window.location.href = redirectUriWithParams;
      })
      .catch((postAuthError) => {
        this._errorHandlerService.handle(postAuthError);
      });
  }

  public asTenant(
    tenant: TenantWithDatasourcesCount
  ): TenantWithDatasourcesCount {
    return tenant;
  }

  public cancel() {
    window.close();
  }

  public confirmAll() {
    this.postAuth(null);
  }

  public confirmForTenant(tenant: TenantWithDatasourcesCount) {
    this.postAuth(tenant);
  }
}
