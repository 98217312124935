import { NgModule } from "@angular/core";
import { AsyncPipe, registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeDe from "@angular/common/locales/de";
import { TransPipe } from "src/app/_pipes/trans.pipe";
import { TranslatablePipe } from "src/app/_pipes/translatable.pipe";
import { YesNoPipe } from "src/app/_pipes/yesNo.pipe";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";

registerLocaleData(localeEn);
registerLocaleData(localeDe);

function getMatPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = "Entries shown:";
  return paginatorIntl;
}

@NgModule({
  exports: [TransPipe, TranslatablePipe, YesNoPipe],
  imports: [MatPaginatorModule],
  declarations: [TransPipe, TranslatablePipe, YesNoPipe],
  providers: [
    { provide: MatPaginatorIntl, useValue: getMatPaginatorIntl() },
    AsyncPipe,
  ],
})
export class AppTransModule {}
