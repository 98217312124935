import { BehaviorSubject, Observable } from "rxjs";

export abstract class ValueStoreService<T> {
  private _value = new BehaviorSubject<T | null>(null);
  private _observable: Observable<T | null>;

  constructor() {
    this._observable = this._value.asObservable();
  }

  public get observable(): Observable<T | null> {
    return this._observable;
  }

  public get current(): T | null {
    return this._value.getValue();
  }

  public hasData(): boolean {
    return this._value.getValue() !== null;
  }

  public isLoaded(): boolean {
    return this._value.getValue() !== null;
  }

  public updateValue(value: T | null) {
    this._value.next(value);
  }

  public initValue(value: T | null) {
    if (this.isLoaded()) {
      return;
    }

    this.updateValue(value);
  }

  public clearValue() {
    this._value.next(null);
  }
}
