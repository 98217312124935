import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { distinctUntilChanged, shareReplay } from "rxjs/operators";
import { CachedValueStoreService } from "./generic/cached-value.store.service";
import { AuthUser } from "../_models/auth-user";

@Injectable({
  providedIn: "root",
})
export class AuthUserStoreService {
  private _store: CachedValueStoreService<AuthUser>;
  private _observable: Observable<AuthUser | null>;

  constructor() {
    this._store = CachedValueStoreService.create<AuthUser>();
    this._observable = this._store.observable
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));
  }

  public get current(): AuthUser | null {
    return this._store.current;
  }

  public get observable(): Observable<AuthUser | null> {
    return this._observable;
  }

  public updateValue(authUser: AuthUser | null): void {
    this._store.updateValue(authUser);
  }
}
