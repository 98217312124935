import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError, timer } from "rxjs";
import {
  catchError,
  mergeMap,
  retryWhen,
  switchMap,
  tap,
} from "rxjs/operators";
import { StatusNotificationService } from "../status-notification.service";
import { ErrorTraceService } from "../error-trace.service";
import { HttpRequest } from "./http.request.model";
import { HttpError } from "./http.error";
import { HttpRequestFactory } from "./http.request-factory.service";
import { RequestTraceService } from "../request-trace.service";

export const genericRetryStrategy =
  ({
    maxAttempts = 5,
    firstDelay = 1000,
    delayExponent = 1.5,
    statusNotificationService,
  }: {
    maxAttempts?: number;
    firstDelay?: number;
    delayExponent?: number;
    statusNotificationService?: StatusNotificationService;
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error: HttpError, i) => {
        const retryAttempt = i + 1;

        if (statusNotificationService) {
          if (error.status === null) {
            statusNotificationService.notifyNetworkError();
          } else if (500 <= error.status && error.status <= 599) {
            statusNotificationService.notifyServerError();
          }
        }

        if (retryAttempt > maxAttempts) {
          console.debug(
            `genericRetryStrategy(): Max attempts ${maxAttempts} exceeded ${retryAttempt}: not retrying`
          );
          return throwError(error);
        }

        if (error.status && ![502, 503].find((e) => e === error.status)) {
          console.debug(
            `genericRetryStrategy(): Got status code ${error.status}: not retrying`
          );
          return throwError(error);
        }

        const currentDelay = firstDelay * Math.pow(delayExponent, retryAttempt);

        console.debug(
          `genericRetryStrategy(): Attempt ${retryAttempt}: retrying in ${currentDelay}ms`
        );

        return timer(currentDelay);
      })
    );
  };

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(
    private _httpClient: HttpClient,
    private _statusNotificationService: StatusNotificationService,
    private _errorTraceService: ErrorTraceService,
    private _requestTraceService: RequestTraceService
  ) {}

  private static async normalizeErrorPromise(
    error: HttpErrorResponse | Error | any,
    request: HttpRequest
  ): Promise<HttpError> {
    if (error instanceof HttpErrorResponse) {
      let errorData = error.error;
      if (errorData instanceof Blob) {
        try {
          errorData = await errorData.text();
        } catch (ignore) {}
      }

      if (typeof errorData === "string") {
        try {
          errorData = JSON.parse(errorData);
        } catch (ignore) {}
      }

      return new HttpError(
        request,
        new Error("HttpErrorResponse: " + error.message),
        error.status,
        errorData && errorData.hasOwnProperty("iqError")
          ? errorData.iqError
          : null
      );
    }

    return new HttpError(request, error);
  }

  public static normalizeError<T>(
    error: HttpErrorResponse | Error | any,
    request: HttpRequest
  ): Observable<T> {
    const p = HttpService.normalizeErrorPromise(error, request);
    return new Observable<T>((subscriber) => {
      p.then(
        (value) => {
          if (!subscriber.closed) {
            subscriber.error(value);
            subscriber.complete();
          }
        },
        (err: any) => subscriber.error(err)
      );
      return subscriber;
    });
  }

  request(requestFactory: HttpRequestFactory): Observable<Object> {
    return new Observable<HttpRequest>((subscriber) => {
      subscriber.next(requestFactory.build());
      subscriber.complete();
    })
      .pipe(
        switchMap((request: HttpRequest) => {
          return this._httpClient
            .request(request.method, request.url, {
              body: request.body,
              responseType: "json",
              observe: "body",
              params: request.query ?? undefined,
              headers: request.headers ?? undefined,
            })
            .pipe(tap(() => this._requestTraceService.add(request)))
            .pipe(
              catchError((error: HttpErrorResponse | Error) => {
                return HttpService.normalizeError<HttpResponse<Blob>>(
                  error,
                  request
                );
              })
            );
        })
      )
      .pipe(
        tap(null, (err: HttpError) => {
          this._errorTraceService.add(err);
        })
      )
      .pipe(
        retryWhen(
          genericRetryStrategy({
            statusNotificationService: this._statusNotificationService,
          })
        )
      );
  }

  requestFile(
    requestFactory: HttpRequestFactory
  ): Observable<HttpResponse<Blob>> {
    return new Observable<HttpRequest>((subscriber) => {
      subscriber.next(requestFactory.build());
      subscriber.complete();
    })
      .pipe(
        switchMap((request: HttpRequest) => {
          return this._httpClient
            .request(request.method, request.url, {
              body: request.body,
              responseType: "blob",
              observe: "response",
              params: request.query ?? undefined,
              headers: request.headers ?? undefined,
            })
            .pipe(tap(() => this._requestTraceService.add(request)))
            .pipe(
              catchError((error: HttpErrorResponse | Error) => {
                return HttpService.normalizeError<HttpResponse<Blob>>(
                  error,
                  request
                );
              })
            );
        })
      )
      .pipe(
        tap(null, (err: HttpError) => {
          this._errorTraceService.add(err);
        })
      )
      .pipe(
        retryWhen(
          genericRetryStrategy({
            statusNotificationService: this._statusNotificationService,
          })
        )
      );
  }
}
