import { Injectable } from "@angular/core";

import langEn from "src/../../src/translations/en.json";
import langDe from "src/../../src/translations/de.json";
import { combineLatest, firstValueFrom, Observable, of } from "rxjs";
import { distinctUntilChanged, first, map, switchMap } from "rxjs/operators";
import { validDefined } from "src/../../src/types/defined";
import { LocaleRepositoryService } from "./repository/locale-repository.service";

const defaultLangData = {
  en: langEn,
  de: langDe,
};

export const SUPPORTED_TRANSLATION_LOCALES: ("de" | "en")[] = ["en", "de"];

export class Translation {
  constructor(
    public readonly translationKey: string,
    public readonly params?: { readonly [key: string]: string | number }
  ) {}
}

export class FallbackTranslation {
  constructor(public readonly translations: Translatable[]) {}
}

export class ConstTranslation {
  constructor(public readonly value: string) {}
}
export class CombinedTranslation {
  constructor(public readonly parts: TranslationType[]) {}
}
export class JsTranslation {
  constructor(
    public readonly callback: (language: string, tenantId?: string) => string
  ) {}
}
export type TranslationType =
  | Translation
  | FallbackTranslation
  | ConstTranslation
  | CombinedTranslation
  | JsTranslation;
export function isTranslationType(value: any): value is TranslationType {
  return (
    value instanceof Translation ||
    value instanceof FallbackTranslation ||
    value instanceof ConstTranslation ||
    value instanceof CombinedTranslation ||
    value instanceof JsTranslation
  );
}

export type Translatable = string | TranslationType;
export function isTranslatable(value: any): value is Translatable {
  return typeof value === "string" || isTranslationType(value);
}

interface TranslationsData {
  [langKey: string]: {
    [transKey: string]: string;
  };
}

@Injectable({
  providedIn: "root",
})
export class TranslationsService {
  private fallbackLanguage = "en";
  private translations: Observable<TranslationsData>;

  constructor(private _localeRepositoryService: LocaleRepositoryService) {
    this.translations = of(defaultLangData);
  }

  public resolve(
    value: Translatable | null | undefined,
    language?: string,
    tenantId?: string,
    autoFallback: boolean = false,
    fallbackText: string | null = null
  ): Observable<string | null> {
    if (value instanceof ConstTranslation) {
      return of(value.value);
    }

    if (value instanceof FallbackTranslation) {
      const observables = value.translations.map((t) => this.resolve(t));
      let combinedObs: Observable<string | null> = of(null);
      observables.forEach((o: Observable<string | null>) => {
        combinedObs = combinedObs.pipe(
          switchMap((v: string | null): Observable<string | null> => {
            if (v === null) {
              return o;
            }

            return of(v);
          })
        );
      });
      return combinedObs;
    }

    if (value instanceof CombinedTranslation) {
      return combineLatest(
        value.parts.map((t) =>
          this.resolve(t, language, tenantId, autoFallback, fallbackText)
        )
      ).pipe(map((v) => v.join("")));
    }

    if (value instanceof JsTranslation) {
      return of(value.callback(language ?? this.fallbackLanguage, tenantId));
    }

    if (value instanceof Translation) {
      return this.translate(
        value.translationKey,
        value.params,
        language,
        tenantId,
        autoFallback ? undefined : "af3707b5-7308-4202-95ad-24371ba8060c"
      ).pipe(
        map((v) =>
          v === "af3707b5-7308-4202-95ad-24371ba8060c" ? fallbackText : v
        )
      );
    }

    return of(value ?? null);
  }

  public resolvePromise(
    value: Translatable | null | undefined,
    language?: string,
    tenantId?: string
  ): Promise<string> {
    return firstValueFrom(
      this.resolve(value, language, tenantId, true).pipe(map((v) => v ?? ""))
    );
  }

  private toPromise<T>(obs: Observable<T>): Promise<T> {
    return new Promise<T>((resolve) => {
      obs.pipe(first()).subscribe((value) => {
        resolve(value);
      });
    });
  }

  public trans(
    translationKey: string,
    params?: { [key: string]: string | number },
    language?: string,
    tenantId?: string
  ): Promise<string> {
    return this.toPromise(
      this.translate(translationKey, params, language, tenantId)
    );
  }

  public translate(
    translationKey: string,
    params?: { [key: string]: string | number },
    language?: string,
    _tenantId?: string, // currently unused
    fallbackText?: string
  ): Observable<string> {
    const paramsObj: { [key: string]: string | number } = params ?? {};

    return combineLatest([
      language
        ? of(language)
        : this._localeRepositoryService.observable.pipe(
            map((language) => {
              return language || this.fallbackLanguage;
            }),
            distinctUntilChanged()
          ),
      this.translations,
    ]).pipe(
      map(([languageWithFallback, translationsData]) => {
        let value = this.getTranslation(
          translationsData,
          translationKey,
          paramsObj,
          languageWithFallback
        );
        if (value !== null) {
          return value;
        }

        if (languageWithFallback !== this.fallbackLanguage) {
          console.warn(
            "translation missing for language",
            translationKey,
            languageWithFallback
          );
          value = this.getTranslation(
            translationsData,
            translationKey,
            paramsObj,
            this.fallbackLanguage
          );
        }

        if (value !== null) {
          return value;
        }

        console.warn("translation missing", translationKey);
        return typeof fallbackText === "string" ? fallbackText : translationKey;
      })
    );
  }

  private getTranslation(
    translations: TranslationsData,
    translationKey: string,
    params: { [key: string]: string | number },
    language: string
  ): string | null {
    if (!translations.hasOwnProperty(language)) {
      return null;
    }
    if (!validDefined(translations[language]).hasOwnProperty(translationKey)) {
      return null;
    }
    const value = validDefined(translations[language])[translationKey];
    if (typeof value !== "string") {
      return null;
    }
    return this.applyParams(translationKey, value, params);
  }

  private applyParams(
    translationKey: string,
    value: string,
    params: { [key: string]: string | number }
  ): string {
    const matches = value.match(/:([a-z0-9_])+/g);
    if (matches) {
      matches.forEach((match) => {
        match = match.substr(1);
        if (!params.hasOwnProperty(match)) {
          console.warn("translation missing parameter", translationKey, match);
        }
      });
    }

    Object.keys(params).forEach((key) => {
      let param: string | number | null | undefined = params[key];
      if (param === undefined) {
        console.warn("translation undefined parameter", translationKey, param);
        param = "";
      }
      if (param === null) {
        param = "";
      }
      if (typeof param === "number") {
        param = param.toString();
      }
      value = value.replace(":" + key, param);
    });
    return value;
  }
}
