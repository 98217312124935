import { Pipe, PipeTransform } from "@angular/core";
import {
  CombinedTranslation,
  ConstTranslation,
  FallbackTranslation,
  JsTranslation,
  Translation,
  TranslationsService,
  TranslationType,
} from "src/app/_services/translations.service";
import { map } from "rxjs/operators";
import { AsyncPipe } from "@angular/common";

@Pipe({ name: "trans" })
export class TransPipe implements PipeTransform {
  constructor(
    private _translationsService: TranslationsService,
    private _asyncPipe: AsyncPipe
  ) {}

  transform(
    key: string | TranslationType,
    params?: { [key: string]: string },
    fallbackText?: string
  ): string {
    if (
      key instanceof Translation ||
      key instanceof FallbackTranslation ||
      key instanceof ConstTranslation ||
      key instanceof CombinedTranslation ||
      key instanceof JsTranslation
    ) {
      if (Object.keys(params ?? {}).length > 0) {
        console.warn(
          "Unsupported trans pipe params with Translation obj instead of key.",
          key,
          params
        );
      }

      return (
        this._asyncPipe.transform(
          this._translationsService
            .resolve(
              key,
              undefined,
              undefined,
              typeof fallbackText === "string",
              fallbackText ?? null
            )
            .pipe(map((v) => v ?? fallbackText ?? ""))
        ) ?? ""
      );
    }

    return (
      this._asyncPipe.transform(
        this._translationsService.translate(
          key,
          params,
          undefined,
          undefined,
          fallbackText
        )
      ) ?? ""
    );
  }
}
