import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { map, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";
import { IqUser } from "src/app/_models/iq-user";
import { Tenant } from "src/app/_services/api/tenant.service";
import { SecuritySubscription } from "src/app/_services/api/security.service";
import { GroupedData, UtilService } from "src/app/_services/util.service";
import {
  TenantRepositoryService,
  TenantWithPublicSubscription,
} from "src/app/_services/repository/tenant-repository.service";
import { UserLanguageStoreService } from "src/app/_store/readonly/user-language.store.service";
import { DebugModeRepositoryService } from "src/app/_services/repository/debug-mode-repository.service";
import { DarkModeRepositoryService } from "src/app/_services/repository/dark-mode-repository.service";

@Component({
  selector: "app-navbar-cmp",
  templateUrl: "navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private _$destroyed = new Subject<void>();
  public $user: Observable<IqUser | null>;
  public $userLanguage: Observable<string | null>;
  public $darkMode: Observable<boolean | null>;
  public $debugMode: Observable<boolean>;
  public $currentTenant: Observable<TenantWithPublicSubscription | null>;

  public $selectableSubscriptionTenants: Observable<
    GroupedData<TenantWithPublicSubscription, SecuritySubscription | null>
  >;
  @Output()
  public toggleSidebar = new EventEmitter<void>();
  @Output()
  public logout = new EventEmitter<void>();
  @Output()
  public changeLanguage = new EventEmitter<string>();
  @Output()
  public switchToTenant = new EventEmitter<Tenant>();

  constructor(
    private _authUserStoreService: AuthUserReadonlyRepositoryService,
    private _userLanguageStoreService: UserLanguageStoreService,
    private _darkModeStoreService: DarkModeRepositoryService,
    private _debugModeRepositoryService: DebugModeRepositoryService,
    private _tenantRepositoryService: TenantRepositoryService
  ) {
    this.$user = this._authUserStoreService.observable;
    this.$userLanguage = this._userLanguageStoreService.observable;
    this.$darkMode = this._darkModeStoreService.observable;
    this.$debugMode = this._debugModeRepositoryService.observable;
    this.$currentTenant = this._tenantRepositoryService.$currentTenant;
  }

  ngOnInit() {
    this.$selectableSubscriptionTenants =
      this._tenantRepositoryService.$tenants.pipe(
        map((tenants: TenantWithPublicSubscription[]) => {
          return UtilService.sortBy(
            UtilService.arrayGroupBy<
              TenantWithPublicSubscription,
              SecuritySubscription | null
            >(
              tenants,
              (t) => t.subscription ?? null,
              (s) => s?.id ?? "_"
            ),
            (g) => g.group?.name ?? "_"
          );
        })
      );

    this._tenantRepositoryService
      .load(false, true)
      .pipe(takeUntil(this._$destroyed))
      .subscribe(() => {});
  }

  ngOnDestroy() {
    this._$destroyed.next();
    this._$destroyed.complete();
  }

  public emitToggleSidebar() {
    this.toggleSidebar.emit();
  }

  public emitLogout() {
    this.logout.emit();
  }

  public emitChangeLanguage(language) {
    this.changeLanguage.emit(language);
  }

  public emitSwitchToTenant(tenant: Tenant) {
    this.switchToTenant.emit(tenant);
  }

  public toggleDarkMode(): void {
    this._darkModeStoreService.toggle();
  }

  public toggleDebugMode(): void {
    this._debugModeRepositoryService.toggle();
  }
}
