import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { CurrentTenantService } from "src/app/_services/current-tenant.service";
import { AuthUserReadonlyRepositoryService } from "src/app/_services/repository/auth-user-readonly-repository.service";
import { Scopes } from "src/app/_models/scope";
import { validDefined } from "src/../../src/types/defined";
import { AuthUser } from "../_models/auth-user";

@Injectable({ providedIn: "root" })
export class IndexGuardService {
  constructor(
    private _router: Router,
    private _currentTenantService: CurrentTenantService,
    private _authUserRepositoryService: AuthUserReadonlyRepositoryService
  ) {}

  public static getIndexRedirectUrl(
    router: Router,
    currentTenantService: CurrentTenantService,
    authUserCallback: () => AuthUser | null
  ): UrlTree {
    const tenantId = currentTenantService.currentTenantId;
    if (!tenantId) {
      return router.createUrlTree(["admin", "tenants"]);
    }

    const routes = [
      {
        route: ["connect-data", "datasources"],
        scope: Scopes.TENANT_DATASOURCES_SHOW,
      },
      {
        route: ["connect-data", "datasources"],
        scope: Scopes.TENANT_DATASOURCES_SHOW,
      },
    ];
    const user = authUserCallback();
    if (!user) {
      return router.createUrlTree(validDefined(routes[0]).route);
    }

    for (let i = 0; i < routes.length; i++) {
      const route = validDefined(routes[i]);
      if (user.iqUser.userCanForTenant(route.scope, tenantId)) {
        return router.createUrlTree(route.route);
      }
    }

    return router.createUrlTree(["user", "profile"]);
  }

  private _canActivate(): Observable<boolean | UrlTree> {
    return of(
      IndexGuardService.getIndexRedirectUrl(
        this._router,
        this._currentTenantService,
        () => this._authUserRepositoryService.currentAuthUser
      )
    );
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this._canActivate();
  }
}
