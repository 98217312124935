<div class="iq-admin-layout">
    <div class="iq-sidebar">
        <div class="iq-sidebar-overlay" (click)="closeMobileSidebar()"></div>
        <app-sidebar-cmp></app-sidebar-cmp>
    </div>
    <div class="iq-main-panel">
        <app-navbar-cmp
                (toggleSidebar)="toggleMobileSidebar()"
                (logout)="logout()"
                (changeLanguage)="changeLanguage($event)"
                (switchToTenant)="switchToTenant($event)"></app-navbar-cmp>
        <router-outlet></router-outlet>
        <div style="flex-shrink: 1; height: 100%;"></div>
        <app-footer-cmp></app-footer-cmp>
    </div>
    <app-session-expired-cmp></app-session-expired-cmp>
</div>